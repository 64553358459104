export const channelIcons = [
  { code: 1, icon: 'gprs' },
  { code: 2, icon: 'fm' }
];

export const powerIcons = [
  { code: 1, icon: 'battery' },
  { code: 3, icon: 'battery_low' }
];

export const modeIcons = [
  { code: 1, icon: 'black_box' },
  { code: 2, icon: 'zzzz' }
];

export const stateNavIcons = [
  { code: 1, icon: 'no_gps' },
  { code: 2, icon: 'gw_not_valid' }
];
