import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DevicePropertiesModule } from './device-properties/device-properties.module';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from 'pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedComponentsModule } from './components/shared-components.module';

import { AlertComponent } from "./alert/alert.component";
import { DeviceCounterComponent } from './device-counter/device-counter.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SelectedCloudsComponent } from './selected-clouds/selected-clouds.component';
import { SplashComponent } from './splash/splash.component';

import { SlidePanel } from './mobile/slide-panel/slide.panel';

@NgModule({
  declarations: [
    AlertComponent,
    DeviceCounterComponent,
    SearchBoxComponent,
    SelectedCloudsComponent,
    SlidePanel,
    SplashComponent
  ],
  imports: [
    CommonModule,
    DevicePropertiesModule,
    DirectivesModule,
    PipesModule,
    RouterModule,
    ScrollingModule,
    SharedComponentsModule
  ],
  exports: [
    AlertComponent,
    DeviceCounterComponent,
    DevicePropertiesModule,
    DirectivesModule,
    RouterModule,
    ScrollingModule,
    SearchBoxComponent,
    SelectedCloudsComponent,
    SlidePanel,
    SplashComponent,
    SharedComponentsModule
  ]
})

export class SharedModule { }
