import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[navClick]'
})
export class NavClickDirective {
  @Output() public onClick = new EventEmitter();
  @Output() public onDoubleClick = new EventEmitter();

  private clickTimer: NodeJS.Timer;
  private preventSingleClick: boolean;

  constructor() { }

  @HostListener('click', [])
  public click(event: MouseEvent): void {
    this.preventSingleClick = false;
    this.clickTimer = setTimeout(() => !this.preventSingleClick && this.onClick.emit(), 200);
  }
  @HostListener('dblclick', [])
  public doubleClick(event: MouseEvent): void {
    this.preventSingleClick = true;
    clearTimeout(this.clickTimer);
    this.onDoubleClick.emit();
  }
}
