import { Size } from "./size.class";
import { isNull } from "app/modules/shared/data/utils";

declare module './size.class' {
  interface Size {
    parse(sizeString: string): Size;
    asString(): string;
  }
}

Size.prototype.parse = function (sizeString: string): Size {
  if (isNull(sizeString)) return new Size();
  const array: Array<string> = sizeString.split(',');
  if (array.length !== 2)
    return new Size();
  return new Size(0, 0, Number(array[0]) || 0, Number(array[1]) || 0);
};

Size.prototype.asString = function (this: Size): string {
  return `${this.height},${this.width}`;
};
