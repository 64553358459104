import { ActivityGroup } from "./activity";
import { Device } from "modules/devices/models/device";

export const activityGroup: ActivityGroup = {
  name: 'Активность',
  activities: [
    { name: '30 минут', isValid: (device: Device) => device.lastNav.time ? device.lastNav.time.diffMinutes(new Date()) <= 30 : false },
    { name: '24 часа', isValid: (device: Device) => device.lastNav.time ? device.lastNav.time.diffHours(new Date()) <= 24 : false },
    { name: 'Все достоверные', isValid: (device: Device) => device.isValid() }
  ]
};
