import { ShortDevice } from "modules/history/models/short-device";
import { Navigation } from "modules/shared/models/navigation";

export class Device extends ShortDevice {
  id: number;
  accNumber: number;
  callSign: string;
  phone: string;
  hardware: string;
  firmware: string;
  regNumber: string;
  name: string;
  clouds: Cloud[];
  selectedClouds: SelectedCloud[] = [];
  validNav: Navigation;
  changed: Changed = Changed.CHANGED_NONE
}

export interface Cloud {
  name: string;
  value: string;
}

export enum Changed {
  CHANGED_NONE,
  CHANGED_TIME,
  CHANGED_ANOTHER
}

export interface SelectedCloud {
  title: string;
  name: string;
}
