import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { HistoryDevice } from 'modules/history/models/history';
import { MapEvent, NavBarEvent } from 'modules/shared/models/events';

import { MapCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'history-selector-button',
  templateUrl: './history-selector.button.html',
  styleUrls: ['./history-selector.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistorySelectorButton implements OnInit {
  public get isShown() {
    return this._isShown;
  }
  public set isShown(value: boolean) {
    this._isShown = value;
    this.changeDetection.detectChanges();
  }
  public isToggled: boolean;

  private _isShown: boolean;

  constructor(
    private readonly changeDetection: ChangeDetectorRef,
    private readonly mapCommService: MapCommService,
    private readonly navBarCommService: NavbarCommService
  ) { }

  ngOnInit(): void {
    this.mapCommService.on(MapEvent.OnHistoryCleared, () => this.isShown = false);
    this.mapCommService.on(MapEvent.OnGotHistory, (history: HistoryDevice[]) => this.isShown = history.length > 1);
    this.navBarCommService.on(NavBarEvent.OnDeviceSelectorToggled, (isToggled: boolean) => this.isToggled = isToggled);
  }

  public toggle(): void {
    this.navBarCommService.emit({ type: NavBarEvent.OnDeviceSelectorToggled, value: this.isToggled });
  }
}
