import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';

import { DeviceProperties } from '../data/device-properties';

@Component({
  selector: 'device-props-modal',
  templateUrl: './device-props.modal.html',
  styleUrls: ['./device-props.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePropsModal extends DeviceProperties {

  constructor(injector: Injector) { super(injector); }
}
