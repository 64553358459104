import { Injectable } from '@angular/core';

import { Device } from 'modules/devices/models/device';
import { HistoryDevice } from '../models/history';

import { DeviceDataService } from 'modules/shared/services/device-data.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryDataService {
  public get history() {
    return this._history;
  }
  public set history(history: Array<HistoryDevice>) {
    this._history = history?.map((historyDevice: HistoryDevice): HistoryDevice => {
      const device = this.deviceService.getDevices().find((device: Device): boolean => device.id === historyDevice.id);
      device && (historyDevice.name = device.getIdentityName());
      return historyDevice;
    });
  }

  private _history: Array<HistoryDevice>;

  constructor(private readonly deviceService: DeviceDataService) { }

  public clear(): void {
    this._history = undefined;
  }
}
