import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MapPoint } from 'modules/history/models/history';

@Component({
  selector: 'coordinate-box',
  templateUrl: './coordinate-box.component.html',
  styleUrls: ['./coordinate-box.component.scss']
})
export class CoordinateBoxComponent {
  @Input() public point: MapPoint;

  @Output() public onPointChanged: EventEmitter<void> = new EventEmitter();

  constructor() { }
}
