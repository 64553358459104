import { Component, Output, EventEmitter } from '@angular/core';

import { HistoryPeriod, PeriodsHelper } from 'modules/history/models/history';

import { historyPeriods } from 'modules/history/data/history-periods';

@Component({
  selector: 'periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent {
  public period: HistoryPeriod = new HistoryPeriod();
  public periods: Array<string> = historyPeriods.map((period: PeriodsHelper): string => period.name);
  public staticPeriod: string;

  @Output() public onPeriodChanged: EventEmitter<HistoryPeriod> = new EventEmitter();

  constructor() { }

  public onDateChanged(): void {
    this.staticPeriod = undefined;
    this.raiseChanged();
  }

  public onStaticPeriodChanged(periodName: string): void {
    this.period = historyPeriods.find((historyPeriod: PeriodsHelper):boolean => historyPeriod.name === periodName)?.getPeriod();
    this.raiseChanged();
  }

  private raiseChanged(): void {
    this.onPeriodChanged.emit(this.period);
  }
}
