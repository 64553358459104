import { NgModule } from '@angular/core';
import { MapButtonsModule } from './buttons/map-buttons.module';
import { MapLayersModule } from './layers/map-layers.module';
import { MapPanelsModule } from './panels/map-panels.module';
import { MapOverlaysModule } from './overlays/map-overlays.module';
import { PipesModule } from 'pipes/pipes.module';
import { SharedModule } from 'modules/shared/shared.module';

import { NavMap } from './nav-map/nav.map';

@NgModule({
  declarations: [
    NavMap
  ],
  imports: [
    MapButtonsModule,
    MapLayersModule,
    MapPanelsModule,
    MapOverlaysModule,
    PipesModule,
    SharedModule
  ],
  exports: [
    NavMap,
    MapButtonsModule,
    MapLayersModule,
    MapPanelsModule,
    MapOverlaysModule
  ]
})
export class MapModule { }
