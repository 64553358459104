import { Injectable } from '@angular/core';

import { Device, Cloud } from 'modules/devices/models/device';
import { DeviceResponse, CloudResponse } from 'modules/devices/models/device.response';
import { HistoryDevice } from 'modules/history/models/history';
import { HistoryResponse } from 'modules/history/models//history.response';
import { MapProp, MapFunc } from 'modules/shared/mapper/mappers';
import { ShortDevice } from 'modules/history/models/short-device';
import { ShortDeviceResponse } from 'modules/history/models/short-device.response';
import { nameof, decimalToHex } from 'app/modules/shared/data/utils';

import { Mapper } from 'app/modules/shared/mapper/mapper';
import { Navigation } from 'modules/shared/models/navigation';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private readonly mapper: Mapper
  ) { }

  public init(): void {
    this.mapper
      .createMap(DeviceResponse, Device)
      .forMember(new MapProp('id', 'Id'))
      .forMember(new MapProp('accNumber', 'ANo'))
      .forMember(new MapProp('callSign', 'CS'))
      .forMember(new MapProp('phone', 'Ph'))
      .forMember(new MapProp('hardware', 'HW'))
      .forMember(new MapProp('firmware', 'FW'))
      .forMember(new MapProp('regNumber', 'RNo'))
      .forMember(new MapProp('name', 'Name'))
      .forMember(new MapFunc((source: DeviceResponse, dest: Device) => dest.clouds = source.CG?.map((cloudItem: CloudResponse) => ({ name: cloudItem.Cloud, value: cloudItem.Group } as Cloud))))
      .forMember(new MapFunc((source: DeviceResponse, dest: Device) => dest.validNav = new Navigation({
        time: source.LTm ? new Date(source.LTm + 'Z') : null,
        latitude: source.LLat,
        longitude: source.LLon,
        course: source.LCrs,
        speed: source.LSpd
      })))
      .forMember(new MapProp('navState', 'CNStt'))
      .withChild(ShortDevice);

    this.mapper
      .createMap(ShortDeviceResponse, ShortDevice)
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.lastNav = new Navigation({
        time: source.CTm ? new Date(source.CTm + 'Z') : null,
        latitude: source.CLat,
        longitude: source.CLon,
        course: source.CCrs,
        speed: source.CSpd
      })))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.channel = {
        code: source.Ch,
        description: source.ChDsc
      }))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.stateNav = {
        code: source.N,
        description: source.NDsc
      }))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.equipState = {
        code: source.E,
        description: source.EDsc
      }))
      .forMember(new MapProp('crewStatement', 'CEStt'))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.mode = {
        code: source.Md,
        description: source.MdDsc
      }))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.power = {
        code: source.Pwr,
        description: source.PwrDsc
      }))
      .forMember(new MapProp('relays', 'RlDsc'))
      .forMember(new MapProp('alarm', 'AlDsc'))
      .forMember(new MapProp('statement', 'CuDsc'))
      .forMember(new MapProp('wire', 'WrDsc'))
      .forMember(new MapProp('guard', 'GdDsc'))
      .forMember(new MapProp('isParked', 'Pkd'))
      .forMember(new MapFunc((source: ShortDeviceResponse, dest: ShortDevice) => dest.receiveTime = source.RTm ? new Date(source.RTm + 'Z') : null))
      .forMember(new MapProp('mark', 'Mark'));

    this.mapper
      .createMap(HistoryResponse, HistoryDevice)
      .forMember(new MapProp('id', 'Id'))
      .forMember(new MapFunc((source: HistoryResponse, dest: HistoryDevice) => dest.color = `#${decimalToHex(source.Clr)}`))
      .forMember(new MapFunc((source: HistoryResponse, dest: HistoryDevice) => dest.coordinates = this.mapper.map(nameof(ShortDeviceResponse), source.Crds)));
  }
}
