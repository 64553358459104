import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy, HostListener, Input } from '@angular/core';

import { Map as OlMap, MapBrowserPointerEvent } from 'ol';
import OlView from 'ol/View';
import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";

import { Device } from 'modules/devices/models/device';
import { Filter } from 'modules/devices/components-shared/filters/data/Filter.class';
import { DeviceEvent, MapEvent } from 'modules/shared/models/events';
//import { NavView, ZoomEvent } from 'modules/map/models/nav-view';

import { MapCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from '../services/ol-map.service';
import { SettingsService } from 'core/services/settings.service';

@Component({
  selector: 'nav-map',
  templateUrl: './nav.map.html',
  styleUrls: ['./nav.map.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavMap implements OnInit, AfterViewInit, OnDestroy {
  @Input() public isCanRotate: boolean = false;

  private readonly ZOOM_SETTING = 'mapZoom';

  private _map: OlMap;
  private saveZoomTimer: NodeJS.Timer;
  private _view: OlView;
  private _zoom: boolean;

  @HostListener('window:orientationchange')
  public onOrientationChange(): void {
    setTimeout(() => { this._map.updateSize(); }, 500);
  }

  constructor(
    private readonly mapService: OlMapService,
    private readonly mapCommService: MapCommService,
    private readonly settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this._map = this.mapService.getMap();
    this._view = this._map.getView();
    this._zoom = this._map.getView().getZoom();
    this.settingsService.trySet(this.ZOOM_SETTING, (value: any): void => this._view.setZoom(value > 15 ? 15 : value));
  }

  ngAfterViewInit(): void {
    this._map.on('pointermove', (event: MapBrowserPointerEvent) => {
      const pixel = this._map.getEventPixel(event.originalEvent);
      const hit = this._map.hasFeatureAtPixel(pixel);
      this._map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this._map.on('moveend', (): void => {
      const zoom = this._map.getView().getZoom();
      if (zoom == this._zoom) return;
      this._zoom = zoom;
      this.mapCommService.emit({ type: MapEvent.OnZoomChanged, value: this._zoom });
      this.saveZoom(zoom);
    });

    //this._view.onZoomChanged$.subscribe((zoomEvent: ZoomEvent) => this.saveZoom(zoomEvent.zoom));
    this._map.updateSize();
  }

  ngOnDestroy(): void {
    //this._view.onZoomChanged$.unsubscribe();
    this.mapService.destroy();
  }

  private saveZoom(zoom: number): void {
    clearTimeout(this.saveZoomTimer);
    this.saveZoomTimer = setTimeout(() => this.settingsService.save(this.ZOOM_SETTING, zoom.toString()), 2000);
  }
}
