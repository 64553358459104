import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LogoutButton } from './logout/logout.button';
import { LocationButton } from './location/location.button';
import { ToggleButton } from './toggle/toggle.button';
import { ZoomControl } from './zoom-control/zoom.control';

@NgModule({
  declarations: [
    LogoutButton,
    LocationButton,
    ToggleButton,
    ZoomControl
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    LogoutButton,
    LocationButton,
    ToggleButton,
    ZoomControl
  ]
})
export class MapButtonsModule { }
