import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { NavBarEvent, MapEvent, MobileEvent } from 'modules/shared/models/events';

import { MapCommService, MobileCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBarComponent implements OnInit {
  public isPortrait: boolean;
  public isSidebarShown: boolean;
  public statusText: string;

  constructor(
    private readonly mobileService: MobileCommService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly mapService: MapCommService,
    private readonly navbarService: NavbarCommService
  ) { }

  ngOnInit(): void {
    this.mapService.on(MapEvent.OnHistoryCleared, () => this.setProp('statusText', undefined));
    this.mobileService.on(MobileEvent.OnOrientationChanged, (value: Orientation): void => this.setProp('isPortrait', value === "portrait"));
    this.navbarService.on(NavBarEvent.OnSidebarToggled, (value: boolean): void => this.setProp('isSidebarShown', value));
    this.navbarService.on(NavBarEvent.OnStatusbarTextChanged, (text: string): void => this.setProp('statusText', text));
  }

  private setProp(property: string, value: any): void {
    this[property] = value;
    this.changeDetector.detectChanges();
  }
}
