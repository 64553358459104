import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { Device } from 'modules/devices/models/device';

@Component({
  selector: 'device-props',
  templateUrl: './device-props.component.html',
  styleUrls: ['./device-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePropertiesComponent {
  @Input() public device: Device;
  @Input() public className: string;

  constructor() { }
}
