import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { DeviceEvent, NavBarEvent, HistoryEvent } from 'modules/shared/models/events';

import { DeviceCommService, NavbarCommService, HistoryCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'mob-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  public get isShown() {
    return this._isShown;
  }
  public set isShown(value: boolean) {
    this._isShown = value;
    this.changeDetection.detectChanges();
  }
  @Input() public isPortrait: boolean;

  public _isShown: boolean;

  constructor(
    private readonly changeDetection: ChangeDetectorRef,
    private readonly deviceService: DeviceCommService,
    private readonly historyService: HistoryCommService,
    private readonly navbarService: NavbarCommService
  ) { }

  ngOnInit(): void {
    this.deviceService.on(DeviceEvent.OnDeviceChoosen, () => this.isShown = false);
    this.historyService.on(HistoryEvent.OnMapSelected, (): void => this.isShown === false && this.navbarService.emit({ type: NavBarEvent.OnSidebarToggled, value: true }));
    this.historyService.on(HistoryEvent.OnListSelected, (): void => this.isShown && this.navbarService.emit({ type: NavBarEvent.OnSidebarToggled, value: false }));
    this.navbarService.on(NavBarEvent.OnSidebarToggled, (value: boolean) => this.isShown = value);
    this.navbarService.on(NavBarEvent.OnDeviceSelectorToggled, (value: boolean): void => value && this.isPortrait && this.navbarService.emit({ type: NavBarEvent.OnSidebarToggled, value: true }));
  }

  public onSwipe(): void {
    this.navbarService.emit({ type: NavBarEvent.OnSidebarToggled, value: false });
  }
}
