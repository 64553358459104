import Feature from "ol/Feature";
import { MultiLineString } from 'ol/geom';
import Circle from 'ol/style/Circle';
import { Icon, Fill, Style, Stroke, Text } from "ol/style";

import { Device } from 'modules/devices/models/device';
import { isNull } from 'app/modules/shared/data/utils';

/**
 * Рассчёт диаметра иконки устройства на карте.
 * @param resolution Разрешение карты.
 * @returns Коэффициент scale для "ol/style/Style"
 */
export function calcScale(resolution: number) {
  const effectiveResolution = Math.min(512, Math.max(1, resolution));
  return 0.1 - Math.floor(Math.log2(effectiveResolution)) / 200;
}

/**
 * Создание стиля для "ol/map/Feature".
 * @param svg svg изображение.
 * @param resolution Разрешение карты | 0.625;
 * @param text Текст, что необходимо отобразить около стиля (опционально).
 * @returns Стиль для "ol/style/Style"
 */
export function createSvgStyle(svg: string, resolution: number = .625): Style {
  if (isNull(svg)) return new Style(null);

  return new Style({
    image: new Icon(({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`,
      scale: calcScale(resolution)
    }))
  });
}

export function addTooltip(style: Style, text: string): void {
  style.setText(new Text({
    text: text,
    fill: new Fill({ color: '#00F' }),
    backgroundFill: new Fill({ color: '#FAFAD2' }),
    backgroundStroke: new Stroke({ color: '#000', width: 1 }),
    textAlign: 'end',
    textBaseline: 'middle',
    padding: [5, 5, 5, 5],
    offsetY: -5,
    offsetX: 20
  }));
}

/**
 * Создание стиля(ей) для кластера(ов)
 * @param feature Feature "ol/map/Feature", содержащий в себе Features.
 * @returns Cтиль | Массив стилей "ol/style/Style".
 */
export function createPointsClusterStyle(feature: Feature): Style | Array<Style> {
  const features: Array<Feature> = feature.get('features');
  if (isNull(features)) return null;

  if (features.length > 1) {
    const radius = features.length <= 100 ? 16 : features.length <= 1000 ? 20 : 24;
    const groupedFeatures: Map<string, Array<Feature>> = features.groupBy(feature => feature.get('device').color);

    const styles: Array<Feature> = [];
    styles.push(new Style({
      image: new Circle({
        radius: radius,
        fill: new Fill({ color: '#ffffffc0' })
      }),
      text: new Text({ text: features.length.toString(), fill: new Fill({ color: '#000' }) })
    }));

    const circleLength = 2 * Math.PI * radius;
    var offset = circleLength;
    groupedFeatures.forEach((groupFeatures: Array<Feature>, color: string) => {
      const segment = groupFeatures.length * circleLength / features.length;
      const style = new Style({
        image: new Circle({
          radius: radius,
          stroke: new Stroke({
            color: color,
            width: 3,
            lineDash: [segment, circleLength - segment],
            lineDashOffset: offset
          })
        })
      });

      styles.push(style);
      offset -= segment;
    });

    return styles;
  }

  return this.createStyle(features.first());
}

/**
 * Создание ломанной линии.
 * @param color Цвет линии (HEX).
 * @param points Массив точек.
 * @returns Ломанная линия (MultiLineString).
 */
export function createMultiLine(color: string, points: Array<Array<number>>): MultiLineString {
  const feature = new Feature({ geometry: new MultiLineString([points]), name: 'Line' });
  feature.setStyle(new Style({ stroke: new Stroke({ color: color, width: 1 }) }));
  return feature;
};

/**
 * Наследование объекта для кастомных компонентов карты.
 * @param child Расширяемый объект.
 * @param parent Расширяющий объект.
 */
export function olInherits(child, parent) {
  child.prototype = Object.create(parent.prototype);
  child.prototype.constructor = child;
};

/**
 * Скрытый стиль окружности для Feature
 * */
export const hiddenStyle: Style = new Style({
  image: new Circle({}),
  stroke: new Stroke({
    color: 'transparent'
  })
});
