import { Component, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { animate, style, trigger, transition } from "@angular/animations";

import { DeviceSelectorPanel } from 'modules/history/components-shared/device-selector/mobile/device-selector-panel/device-selector.panel';

@Component({
  selector: 'slide-panel',
  templateUrl: './slide.panel.html',
  styleUrls: ['./slide.panel.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class SlidePanel {
  @Input() public isShown: boolean;

  @ViewChild('container', { read: ViewContainerRef }) embeddedContainer: ViewContainerRef;

  constructor(private readonly resolver: ComponentFactoryResolver) { }

  ngAfterViewInit() {
    console.log(this.embeddedContainer);
    const component = this.resolver.resolveComponentFactory(DeviceSelectorPanel);
    this.embeddedContainer.createComponent(component);
  }
}
