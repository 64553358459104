import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Injector } from "@angular/core";

import { Device } from 'modules/devices/models/device';
import { DeviceEvent } from 'modules/shared/models/events';

import { DeviceCommService } from 'modules/shared/services/communication.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceProperties {
  public get device() {
    return this._device;
  }
  public set device(value: Device) {
    this._device = value;
    this.changeDetection.detectChanges();
  }

  private readonly changeDetection: ChangeDetectorRef;
  private readonly deviceService: DeviceCommService;

  private _device: Device;

  constructor(injector: Injector) {
    this.changeDetection = injector.get(ChangeDetectorRef);
    this.deviceService = injector.get(DeviceCommService);
  }

  ngOnInit(): void {
    this.deviceService.on(DeviceEvent.OnDeviceDetail, (device: Device) => this.device = device);
  }
}
