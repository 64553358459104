import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import Draw, { createBox } from 'ol/interaction/Draw';
import { Feature, Map as OlMap } from 'ol';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { toLonLat, fromLonLat } from 'ol/proj';

import { MapEvent } from 'modules/shared/models/events';
import { MapPoint } from 'modules/history/models/history';

import { OlMapService } from 'modules/map/services/ol-map.service';
import { MapCommService } from 'modules/shared/services/communication.service';
import { isNull } from 'app/modules/shared/data/utils';

@Component({
  selector: 'draw-layer'
})
export class DrawLayer implements OnInit {
  private draw: Draw;
  private drawnFeature: Feature;
  private drawLayer: VectorLayer;
  private drawSource: VectorSource = new VectorSource();
  private map: OlMap;

  constructor(
    private readonly olMapService: OlMapService,
    private readonly mapService: MapCommService
  ) { }

  ngOnInit(): void {
    this.map = this.olMapService.getMap();
    this.drawLayer = new VectorLayer({ name: 'DrawLayer', source: this.drawSource });
    this.map.addLayer(this.drawLayer);

    this.mapService.on(MapEvent.OnDrawToggled, (state: boolean) => {
      this[state ? 'startDrawing' : 'stopDrawing'].call(this);
    });

    this.mapService.on(MapEvent.OnDrawnChanged, (coordinates: Array<Array<number>>) => {
      isNull(this.drawnFeature) && this.initPolygon();

      const featureGeometry = this.drawnFeature.getGeometry();
      const featureCoordinates = featureGeometry.getCoordinates();
      featureCoordinates[0][0] = fromLonLat([coordinates[0][0], coordinates[0][1]]);
      featureCoordinates[0][1] = fromLonLat([coordinates[0][0], coordinates[1][1]]);
      featureCoordinates[0][2] = fromLonLat([coordinates[1][0], coordinates[1][1]]);
      featureCoordinates[0][3] = fromLonLat([coordinates[1][0], coordinates[0][1]]);
      featureCoordinates[0][4] = fromLonLat([coordinates[0][0], coordinates[0][1]]);
      featureGeometry.setCoordinates(featureCoordinates);

      this.map.zoomToFit(this.drawnFeature);
    });

    this.mapService.on(MapEvent.OnDrawCleared, (): void => {
      this.drawSource.clear();
      this.drawnFeature = undefined;
    });
  }

  private initPolygon(): void {
    this.drawnFeature = new Feature({
      geometry: new Polygon([[], [], [], [], []])
    });
    this.drawSource.addFeature(this.drawnFeature);
  }

  private startDrawing(): void {
    this.drawSource.clear();
    this.draw = new Draw({
      geometryFunction: createBox(),
      source: this.drawSource,
      type: 'Circle'
    });
    this.draw.on('drawend', (event) => {
      this.drawnFeature = event.feature;
      const coordinates = this.drawnFeature.getGeometry().getCoordinates()[0]
        .map((coordinate: Array<number>) => {
          const parsedCoordinate: Array<number> = toLonLat(coordinate);
          return new MapPoint(parseFloat(parsedCoordinate[1].toFixed(6)), parseFloat(parsedCoordinate[0].toFixed(6)));
        });
      this.mapService.emit({ type: MapEvent.OnHistoryAreaDrawn, value: coordinates });

      this.stopDrawing();
    });
    this.map.addInteraction(this.draw);
  }

  private stopDrawing(): void {
    this.map.removeInteraction(this.draw);
    this.draw = undefined;
  }
}
