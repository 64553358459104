import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import Logout from 'modules/shared/components/logout/logout';

import { AlertService } from 'core/services/alert.service';
import { AuthenticationService } from 'modules/authorization/services/authentication.service';

@Component({
  selector: 'logout-button',
  templateUrl: './logout.button.html',
  styleUrls: ['./logout.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutButton extends Logout {
  constructor(
    private readonly alertService: AlertService,
    authService: AuthenticationService,
    router: Router
  ) { super(authService, router); }

  public onLogout(): void {
    this.alertService.clean();
    this.logout();
  }
}
