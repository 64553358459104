export abstract class MapItem {
  abstract map(source: any, dest: any): void;
}

/*
 * Маппинг посредством вызова функции
 */
export class MapFunc extends MapItem {

  constructor(
    private readonly mapFunc: Action
  ) { super(); }

  public map(source: any, dest: any): void {
    this.mapFunc.call(this, source, dest);
  }
}

/*
 * Маппинг посредством присвоения свойства одного объекта(srcProp) к другому(destProp).
 */
export class MapProp extends MapItem {

  constructor(
    private readonly destProp: string,
    private readonly srcProp: string
  ) { super(); }

  public map(source: any, dest: any): void {
    dest[this.destProp] = source[this.srcProp];
  }
}
