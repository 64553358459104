import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private readonly storage: StorageService) { }

  public get(key: string): any {
    return this.storage.get(key);
  }

  public set(key: string, value: any): void {
    this.storage.set(key, value);
  }

  public clear(): void {
    this.storage.clear();
  }
}
