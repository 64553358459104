export abstract class ScreenBase {
  constructor(
    protected readonly document: any
  ) { }

  public abstract enable(): void;
  public abstract disable(): void;
}

export class Common extends ScreenBase {

  constructor(
    document: any
  ) {
    super(document);
  }

  public enable(): void {
    this.document.documentElement.requestFullscreen();
  }
  public disable(): void {
    this.document.exitFullscreen();
  }
}

/* IE/Edge */
export class Edge extends ScreenBase {

  constructor(
    document: any
  ) {
    super(document);
  }

  public enable(): void {
    this.document.documentElement.msRequestFullscreen();
  }
  public disable(): void {
    this.document.msExitFullscreen();
  }
}

/* Firefox */
export class Firefox extends ScreenBase {

  constructor(
    document: any
  ) {
    super(document);
  }

  public enable(): void {
    this.document.documentElement.mozRequestFullScreen();
  }

  public disable(): void {
    this.document.mozCancelFullScreen();
  }
}

/* Chrome, Safari and Opera */
export class Other extends ScreenBase {

  constructor(
    document: any
  ) {
    super(document);
  }

  public enable(): void {
    this.document.documentElement.webkitRequestFullscreen();
  }
  public disable(): void {
    this.document.webkitExitFullscreen();
  }
}
