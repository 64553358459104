import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Device, Cloud, SelectedCloud, Changed } from 'modules/devices/models/device';
import { DeviceEvent } from 'modules/shared/models/events';

import { CommunicationService } from 'modules/shared/services/communication.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

const highlights = new Map<Changed, string>([
  [Changed.CHANGED_TIME, 'lightgreen'],
  [Changed.CHANGED_ANOTHER, 'lightblue']
]);

@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceComponent {
  public get device() {
    return this._device;
  }
  @Input() public set device(device: Device) {
    this.setHighlight(device.changed);
    this._device = device;
  }

  private _device: Device;

  constructor(
    private readonly changeDetection: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  public get id() {
    return this.device.id;
  }

  public get isIdShort() {
    return this.device.id < 1000000;
  }

  public get leftCloud() {
    return this.device.selectedClouds[0];
  }

  public get rightCloud() {
    return this.device.selectedClouds[1];
  }

  public get channel() {
    return this.device.channel;
  }

  private _highlightClass: string;
  public get highlight() {
    return this._highlightClass;
  }

  public get power() {
    return this.device.power;
  }

  public get mode() {
    return this.device.mode;
  }

  public get stateNav() {
    return this.device.stateNav;
  }

  public get time() {
    return this.device.lastNav.time;
  }

  public get timeValid() {
    return this.device.validNav.time;
  }

  public get course() {
    return this.device.validNav.course;
  }

  public get mark() {
    return this.sanitizer.bypassSecurityTrustHtml(this.device.mark?.replace(/ (width|height)=\"\d+\"/g, " $1=\"24\""));
  }

  public get equipStateDescription() {
    return this.device.equipState?.description || '';
  }

  private setHighlight(changedProps: Changed) {
    //if (changedProps in Changed && changedProps !== Changed.CHANGED_NONE) {
    this._highlightClass = 'invisible'; //highlights.get(changedProps);
    setTimeout(() => {
      this._highlightClass = undefined;
      this.changeDetection.detectChanges();
    }, 200);
    //}
  }
}
