import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OwlModule } from 'modules/owl/owl.module';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from 'modules/shared/shared.module';

import { CoordinateBoxComponent } from './coordinate-box/coordinate-box.component';
import { GeoFenceComponent } from './geo-fence/geo-fence.component';
import { HistoryDevicesComponent } from './history-devices/history-devices.component';
import { PeriodsComponent } from './periods/periods.component';
import { RequestButton } from './request-button/request.button';
import { RequestValidsComponent } from './request-valids/request-valids.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  declarations: [
    CoordinateBoxComponent,
    GeoFenceComponent,
    HistoryDevicesComponent,
    PeriodsComponent,
    RequestButton,
    RequestValidsComponent,
    TimePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    OwlModule,
    SharedModule
  ],
  exports: [
    CoordinateBoxComponent,
    GeoFenceComponent,
    HistoryDevicesComponent,
    PeriodsComponent,
    RequestButton,
    RequestValidsComponent,
    TimePickerComponent
  ]
})
export class HistoryComponentsModule { }
