import { Component, OnInit, Inject } from '@angular/core';

import Feature from "ol/Feature";
import { Map as OlMap } from 'ol';
import OlView from 'ol/View';
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import { Cluster, Vector } from "ol/source";
import { Style } from "ol/style";
import { addTooltip, createSvgStyle, calcScale } from 'modules/map/data/ol-map-utils';

import { Device } from 'modules/devices/models/device';
import { DeviceEvent, MapEvent } from 'modules/shared/models/events';
import { Filter } from 'modules/devices/components-shared/filters/data/filter.class';
import { SettingKeys } from 'core/data/settings';
import { isNull, parseBoolean, notNull } from 'modules/shared/data/utils';

import { DeviceCommService, MapCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from 'modules/map/services/ol-map.service';
import { SettingsService } from 'core/services/settings.service';

@Component({
  selector: 'device-layer'
})
export class DeviceLayer implements OnInit {
  private clusterSource: Cluster;
  private deviceLayer: VectorLayer;
  private deviceSource: Vector = new Vector();
  private filter: Filter = new Filter();
  private map: OlMap;
  private view: OlView;
  private isTooltipsShown: boolean;

  constructor(
    private readonly deviceService: DeviceCommService,
    private readonly mapCommService: MapCommService,
    private readonly mapService: OlMapService,
    private readonly settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.map = this.mapService.getMap();
    this.clusterSource = new Cluster({ distance: 30, source: this.deviceSource });
    this.deviceLayer = new VectorLayer({ name: 'devicesLayer', source: this.deviceSource });
    this.map.addLayer(this.deviceLayer);
    this.view = this.map.getView();

    this.settingsService.trySet(SettingKeys.DEVICE_TOOLTIP, (value: string) => this.isTooltipsShown = parseBoolean(value));

    this.deviceService.on(DeviceEvent.OnDeviceReceived, (device: Device): void => {
      let feature = this.deviceSource.getFeatureById(device.id);
      if (isNull(feature)) {
        feature = new Feature();
        feature.setId(device.id);
        this.deviceSource.addFeature(feature);
      }

      feature.updateDevice(device);

      if (device.hasValidCoordinate()) {
        feature.setGeometry(new Point(device.getValidCoordinate()));
      }

      this.setStyle(feature);

      this.deviceSource.changed();
    });
    this.deviceService.on(DeviceEvent.OnDeviceChoosen, (device: Device) => {
      if (device.hasValidCoordinate()) {
        const coordinate = device.getValidCoordinate();
        this.map.toCenter(coordinate);
        this.map.animateFeature(coordinate);
      }
    });
    this.deviceService.on(DeviceEvent.OnFilterChanged, (filter: Filter): void => {
      this.filter = filter;
      this.deviceSource.getFeatures().forEach((feature: Feature): void => this.setStyle(feature));
      this.deviceSource.changed();
    });

    this.mapCommService.on(MapEvent.OnGotHistory, () => this.deviceLayer.setVisible(false));
    this.mapCommService.on(MapEvent.OnHistoryCleared, () => this.deviceLayer.setVisible(true));
    this.mapCommService.on(MapEvent.OnDeviceTooltipToggled, (isShown: boolean) => {
      this.isTooltipsShown = isShown;
      this.deviceSource.getFeatures().forEach((feature: Feature): void => {
        const device = feature.get('device');
        if (isNull(device) || this.filter.inCriterias(device) === false) return;
        const style = createSvgStyle(device.mark, this.view.getResolution());
        this.isTooltipsShown && addTooltip(style, device.getIdentityName());
        feature.setStyle(style);
      });
    });
    this.mapCommService.on(MapEvent.OnZoomChanged, (zoom: number): void => {
      const scale = calcScale(this.view.getResolution());
      this.deviceSource.getFeatures().forEach((feature: Feature) => {
        feature.getStyle()?.getImage()?.setScale(scale);
      });
    });
  }

  private setStyle(feature: Feature): void {
    let style = new Style(null);
    const device = feature.get('device');
    if (notNull(device) && this.filter.inCriterias(device)) {
      style = createSvgStyle(device.mark, this.view.getResolution());
      this.isTooltipsShown && addTooltip(style, device.getIdentityName());
    }

    feature.setStyle(style);
  }
}
