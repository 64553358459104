import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesDesktopModule } from './desktop/devices-desktop.module';
import { DevicesMobileModule } from './mobile/devices-mobile.module';

@NgModule({
  imports: [
    CommonModule,
    DevicesDesktopModule,
    DevicesMobileModule
  ],
  exports: [
    DevicesDesktopModule,
    DevicesMobileModule
  ]
})
export class DevicesModule { }
