import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlsPanel } from './controls/controls.panel';
import { NavPanel } from './nav/nav.panel';
import { RoutePanel } from './route/route.panel';

@NgModule({
  declarations: [
    ControlsPanel,
    NavPanel,
    RoutePanel
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ControlsPanel,
    NavPanel,
    RoutePanel
  ]
})
export class MapPanelsModule { }
