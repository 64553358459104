import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsSharedModule } from '../components-shared/components-shared.module';
import { SharedModule } from 'modules/shared/shared.module';
import { PipesModule } from 'pipes/pipes.module';

import { DevicesListView } from './views/devices-list/devices-list.view';

@NgModule({
  declarations: [
    DevicesListView
  ],
  imports: [
    CommonModule,
    ComponentsSharedModule,
    SharedModule,
    PipesModule
  ],
  exports: [
    DevicesListView
  ]
})
export class DevicesMobileModule { }
