import { Device } from "modules/devices/models/device";

declare module 'modules/devices/models/device' {
  interface Device {
    /**
     * Проверка наличия в объекте валидных координат.
     * @returns Факт наличия координат.
     * */
    hasValidCoordinate(): boolean;
    /**
     * Получение валидных координат.
     * @returns Массив координат(fromLonLat) или null.
     */
    getValidCoordinate(): Array<number> | null;
    /**
     * Получение уникального наименование устройства. В зависимости от доступных полей.
     * @returns Наименование устройства.
     * */
    getIdentityName(): string;
  }
}
//TODO Вынести в общую логику с shortDevice, hasLastCoordinate
Device.prototype.hasValidCoordinate = function (this: Device): boolean {
  return this.hasOwnProperty('validNav') ? this.validNav.hasCoordinate() : false;
};

Device.prototype.getValidCoordinate = function (this: Device): Array<number> | null {
  return this.hasValidCoordinate() ? this.validNav.getCoordinate() : null;
};

Device.prototype.getIdentityName = function (this: Device): string {
  if (this.name) return this.name;
  else if (this.regNumber) return this.regNumber;
  else return `Терминал #${this.id}`;
};
