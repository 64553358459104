import { ShortDevice } from "modules/history/models/short-device";

declare module 'modules/history/models/short-device' {
  interface ShortDevice {
    /**
     * Проверка наличия последних координат.
     * @returns Результат проверки.
     * */
    hasLastCoordinate(): boolean;

    /**
     * Получение "последних" координат.
     * @returns Массив координат(fromLonLat) или null.
     * */
    getLastCoordinate(): Array<Number> | null;
    /**
     * Проверка валидности(достоверности) устройства.
     * @returns Факт валидности устройства.
     * */
    isValid(): boolean;

    /**
     * Проверка валидности работы модема.
     * @returns Факт валидности модема.
     * */
    isFailedModem(): boolean;

    /**
     * Проверка валидности антенны.
     * @returns Факт валидности антенны.
     * */
    isFailedAntenna(): boolean;
  }
}

ShortDevice.prototype.hasLastCoordinate = function (this: ShortDevice) {
  return this.hasOwnProperty('lastNav') ? this.lastNav.hasCoordinate() : false;
};

ShortDevice.prototype.getLastCoordinate = function (this: ShortDevice) {
  return this.hasLastCoordinate() ? this.lastNav.getCoordinate() : null;
};

ShortDevice.prototype.isValid = function (this: ShortDevice) {
  return this.stateNav?.code == 0;
};

ShortDevice.prototype.isFailedModem = function (this: ShortDevice) {
  return (this.equipState.code & 2) !== 0;
};

ShortDevice.prototype.isFailedAntenna = function (this: ShortDevice) {
  return (this.equipState.code & 1) != 0;
};  
