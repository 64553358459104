import { ModuleWithProviders } from "@angular/compiler/src/core";
import { Route, RouterModule } from "@angular/router";

import { NavigationPage } from "modules/navigation/desktop/pages/navigation/navigation.page";

import { MobileGuard } from "core/guards/mobile.guard";

import { SettingsResolver } from "resolvers/settings.resolver";

import { devicesDesktopRoutes } from "modules/devices/desktop/devices-desktop.routes";
import { historyDesktopRoutes } from "modules/history/desktop/history-desktop.routes";
import { timeMachineDesktopRoutes } from "modules/time-machine/desktop/time-machine-desktop.routes";

const routes: Array<Route> = [{
  path: 'navigation',
  component: NavigationPage,
  resolve: { settings: SettingsResolver },
  canActivate: [MobileGuard],
  children: [
    devicesDesktopRoutes,
    historyDesktopRoutes,
    timeMachineDesktopRoutes
  ]
}];

export const navigationDesktopRoutes: ModuleWithProviders = RouterModule.forChild(routes);
