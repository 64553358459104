import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavBase } from 'modules/navigation/data/nav-base';

import { StyleService } from 'core/services/style.service';

@Component({
  templateUrl: './navigation.page.html',
  styleUrls: ['./navigation.page.scss']
})
export class NavigationPage extends NavBase implements OnInit, OnDestroy {
  public isPropsShow: boolean = false;
  public isDevicesHidden: boolean = false;

  constructor(
    private readonly styleService: StyleService
  ) { super(); }

  ngOnInit(): void {
    this.styleService.applyStyles();
  }

  ngOnDestroy(): void {
    this.styleService.resetStyles();
  }

  public onDevicePropsChanged(isSet: boolean) {
    this.isPropsShow = isSet;
  }
}
