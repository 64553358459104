import { Component, OnInit, Inject } from '@angular/core';

import Feature from "ol/Feature";
import { Map as OlMap } from 'ol';
import { MultiLineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import VectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";
import { Style, Stroke } from "ol/style";

import { HistoryDevice } from 'modules/history/models/history';
import { HistoryEvent, MapEvent } from 'modules/shared/models/events';
import { ShortDevice } from 'modules/history/models/short-device';
import { createMultiLine } from 'modules/map/data/ol-map-utils';
import { pointsValidators } from 'modules/map/data/points-validators';

import { MapCommService, HistoryCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'history-lines-layer'
})
export class HistoryLineLayer implements OnInit {
  private lineLayer: VectorLayer;
  private lineSource: VectorSource = new VectorSource();
  private map: OlMap;

  constructor(
    private readonly historyService: HistoryCommService,
    private readonly mapService: MapCommService,
    private readonly olMapService: OlMapService
  ) { }

  ngOnInit(): void {
    this.map = this.olMapService.getMap();
    this.lineLayer = new VectorLayer({ name: 'historyLinesLayer', source: this.lineSource/*, style:  */ });
    this.map.addLayer(this.lineLayer);

    this.mapService.on(MapEvent.OnGotHistory, (history: HistoryDevice[]): void => {
      const features: Array<Feature> = [];

      history.forEach((history: HistoryDevice): void => {
        let previous: ShortDevice = history.coordinates.first();
        let points: Array<Array<number>> = [];

        history.coordinates.forEach((device: ShortDevice) => {
          if (device.isValid()) {
            if (pointsValidators.some((func: Func): boolean => func.call(this, device, previous))) {
              if (points.empty() === false) {
                const line = createMultiLine(history.color, points);
                line.set('id', history.id);
                features.push(line);
                points = [];
              }
            }

            points.push(fromLonLat([device.lastNav.longitude, device.lastNav.latitude]));
            previous = device;
          }
        });

        if (points.empty() === false)
          features.push(createMultiLine(history.color, points));
      });

      this.lineSource = new VectorSource({ features: features });
      this.lineLayer.setSource(this.lineSource);
    });
    this.mapService.on(MapEvent.OnHistoryCleared, (): void => this.lineSource.clear());

    this.historyService.on(HistoryEvent.OnHistoryDeleted, (device: HistoryDevice): void => { });
  }
}
