import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import { getCenter } from 'ol/extent';
import { getVectorContext } from 'ol/render';
import { RenderEvent } from 'ol/render/Event';
import { unByKey } from "ol/Observable";
import { hiddenStyle } from "./ol-map-utils";

/** Animate feature on a vector layer 
* @fires animationstart, animationend
* @param {ol.Feature} feature Feature to animate
* @param {ol.featureAnimation|Array<ol.featureAnimation>} fanim the animation to play
* @param {boolean} useFilter use the filters of the layer
* @return {olx.animationControler} an object to control animation with start, stop and isPlaying function
*/
VectorLayer.prototype.animateFeature = function (feature, fanim, useFilter) {
  var self = this;
  var listenerKey;
  // Save style
  var style = feature.getStyle();
  var flashStyle = style || (this.getStyleFunction ? this.getStyleFunction()(feature) : null);
  if (!flashStyle) flashStyle = [];
  if (!(flashStyle instanceof Array)) flashStyle = [flashStyle];
  // Structure pass for animating
  var event: animateEvent = {
    // Frame context
    vectorContext: null,
    frameState: null,
    start: 0,
    time: 0,
    elapsed: 0,
    extent: false,
    // Feature information
    feature: feature,
    geom: feature.getGeometry(),
    typeGeom: feature.getGeometry().getType(),
    bbox: feature.getGeometry().getExtent(),
    coord: getCenter(feature.getGeometry().getExtent()),
    style: flashStyle,
    type: '',
    inversePixelTransform: [],
    context: null,
    rotation: 0
  };
  if (!(fanim instanceof Array)) fanim = [fanim];
  // Remove null animations
  for (var i = fanim.length - 1; i >= 0; i--) {
    if (fanim[i].duration_ === 0) fanim.splice(i, 1);
  }
  var nb = 0, step = 0;
  // Filter availiable on the layer
  var filters = (useFilter && this.getFilters) ? this.getFilters() : [];

  function animate(renderEvent: RenderEvent) {
    event.type = renderEvent.type;
    try {
      event.vectorContext = renderEvent.vectorContext || getVectorContext(renderEvent);
    } catch (e) { /* nothing todo */ }
    event.frameState = renderEvent.frameState;
    event.inversePixelTransform = renderEvent.inversePixelTransform;
    if (!event.extent) {
      event.extent = renderEvent.frameState.extent;
      event.start = renderEvent.frameState.time;
      event.context = renderEvent.context;
    }
    event.time = renderEvent.frameState.time - event.start;
    event.elapsed = event.time / fanim[step].duration_;
    if (event.elapsed > 1) event.elapsed = 1;
    // Filter
    renderEvent.context.save();
    filters.forEach(function (f) {
      if (f.get('active')) f.precompose(renderEvent);
    });
    if (this.getOpacity) {
      renderEvent.context.globalAlpha = this.getOpacity();
    }
    // Stop animation?
    if (!fanim[step].animate(event)) {
      nb++;
      // Repeat animation
      if (nb < fanim[step].repeat_) {
        event.extent = false;
      } else if (step < fanim.length - 1) {
        // newt step
        fanim[step].dispatchEvent({ type: 'animationend', feature: feature });
        step++;
        nb = 0;
        event.extent = false;
      } else {
        // the end
        stop();
      }
    } else {
      var animEvent = {
        type: 'animating',
        step: step,
        start: event.start,
        time: event.time,
        elapsed: event.elapsed,
        rotation: event.rotation || 0,
        geom: event.geom,
        coordinate: event.coord,
        feature: feature
      };
      fanim[step].dispatchEvent(animEvent);
      self.dispatchEvent(animEvent);
    }
    filters.forEach(function (f) {
      if (f.get('active')) f.postcompose(renderEvent);
    });
    renderEvent.context.restore();
    // tell OL3 to continue postcompose animation
    renderEvent.frameState.animate = true;
  }
  // Stop animation
  function stop(options?: any) {
    unByKey(listenerKey);
    listenerKey = null;
    feature.setStyle(style);
    // Send event
    var event = { type: 'animationend', feature: feature };
    if (options) {
      for (var i in options) if (options.hasOwnProperty(i)) {
        event[i] = options[i];
      }
    }
    fanim[step].dispatchEvent(event);
    self.dispatchEvent(event);
  }
  // Launch animation
  function start(options?: any) {
    if (fanim.length && !listenerKey) {
      listenerKey = self.on(['postcompose', 'postrender'], animate.bind(self));
      // map or layer?
      if (self.renderSync) self.renderSync();
      else self.changed();
      // Hide feature while animating
      feature.setStyle(fanim[step].hiddenStyle || hiddenStyle);
      // Send event
      var event = { type: 'animationstart', feature: feature };
      if (options) {
        for (var i in options) if (options.hasOwnProperty(i)) {
          event[i] = options[i];
        }
      }
      fanim[step].dispatchEvent(event);
      self.dispatchEvent(event);
    }
  }
  start();
  // Return animation controler
  return {
    start: start,
    stop: stop,
    isPlaying: function () { return (!!listenerKey); }
  };
};

interface animateEvent {
  // Frame context
  vectorContext: any,
  frameState: any,
  start: number,
  time: number,
  elapsed: number,
  extent: false,
  // Feature information
  feature: any,
  geom: any,
  typeGeom: any,
  bbox: any,
  coord: any,
  style: any,
  type: '',
  inversePixelTransform: [],
  context: null,
  rotation: number
}
