import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[navTap]'
})
export class NavTapDirective {
  @Output() public onTap = new EventEmitter();
  @Output() public onDoubleTap = new EventEmitter();

  private clickTimer: NodeJS.Timer;
  private preventSingleClick: boolean;

  constructor() { }

  @HostListener('tap', ['$event'])
  public onTapRaised(event: any): void {
    if (event.tapCount === 1) {
      this.preventSingleClick = false;
      this.clickTimer = setTimeout(() => !this.preventSingleClick && this.onTap.emit(), 200);
    } else if (event.tapCount === 2) {
      this.preventSingleClick = true;
      clearTimeout(this.clickTimer);
      this.onDoubleTap.emit();
    }
  }
}
