import { Component, Input, OnInit } from '@angular/core';

import { Map, Feature, Geolocation } from "ol";
import { Circle, Fill, Style, Stroke } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";

import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'location-button',
  templateUrl: './location.button.html',
  styleUrls: ['./location.button.scss']
})
export class LocationButton implements OnInit {
  @Input() public className: string;
  public onClicked: Action = this.enable;

  private geolocation: Geolocation;
  private layer: VectorLayer;
  private lastCoordinates: number[];
  private accuracyFeature: Feature = new Feature();
  private positionFeature: Feature = new Feature();

  private located = false;
  private mapInCenter = false;

  public get stateIcon() {
    return this.geolocation ? this.mapInCenter ? "gps_fixed" : "gps_not_fixed" : "gps_off";
  }

  private map: Map;

  constructor(
    private readonly mapService: OlMapService
  ) { }

  ngOnInit(): void {
    this.map = this.mapService.getMap();
  }

  private mapToCenter(): void {
    if (this.lastCoordinates)
      this.map.toCenter(this.lastCoordinates);
  }

  private updateMapInCenter(): void {
    const lastCoordinates = this.lastCoordinates;
    const mapCenter = this.map.getView().getCenter();
    this.mapInCenter = lastCoordinates && Math.abs(lastCoordinates[0] - mapCenter[0]) < 100 && Math.abs(lastCoordinates[1] - mapCenter[1]) < 100;
  }

  private enable(): void {
    this.accuracyFeature = new Feature();
    this.positionFeature = new Feature();
    this.positionFeature.setStyle(new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    }));

    this.layer = new VectorLayer({
      source: new VectorSource({
        features: [this.accuracyFeature, this.positionFeature]
      })
    });
    this.map.addLayer(this.layer);
    this.map.getView().on('change:center', () => {
      this.updateMapInCenter();
    });

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: this.map.getView().getProjection()
    });
    this.geolocation.on('change:position', () => {
      this.lastCoordinates = this.geolocation.getPosition();
      if (this.lastCoordinates && !this.located) {
        this.mapToCenter();
        this.located = true;
      }
      this.positionFeature.setGeometry(this.lastCoordinates ? new Point(this.lastCoordinates) : null);
      this.updateMapInCenter();
    });
    this.geolocation.on('change:accuracyGeometry', () => this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry()));
    this.geolocation.setTracking(true);
    this.onClicked = this.tracking;
  }

  private tracking(): void {
    if (!this.located)
      this.disable();

    if (this.lastCoordinates)
      if (!this.mapInCenter) {
        this.mapToCenter();
      } else {
        this.disable();
      }
  }

  private disable(): void {
    this.geolocation.setTracking(false);
    this.geolocation = undefined;
    this.accuracyFeature.setGeometry(undefined);
    this.positionFeature.setGeometry(undefined);
    this.map.removeLayer(this.layer);
    this.layer = undefined;
    this.located = false;
    this.onClicked = this.enable;
  }
}
