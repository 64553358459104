import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'modules/material/material.module';

import { FiltersMenu } from './desktop/filters-menu/filters.menu';
import { FiltersPanel } from './mobile/filters-panel/filters.panel';

@NgModule({
  declarations: [
    FiltersMenu,
    FiltersPanel
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    FiltersMenu,
    FiltersPanel
  ]
})
export class FiltersModule { }
