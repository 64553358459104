import { Component, Injector, ViewChild, ChangeDetectionStrategy } from "@angular/core";

import { DevicesBase } from "modules/devices/components/devices";
import { SelectedCloudsComponent } from "modules/shared/selected-clouds/selected-clouds.component";

@Component({
  selector: 'devices',
  templateUrl: './devices-list.view.html',
  styleUrls: ['./devices-list.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesListView extends DevicesBase {

  constructor(injector: Injector) { super(injector); }

  @ViewChild("clouds", { read: SelectedCloudsComponent, static: true }) clouds: SelectedCloudsComponent;
}
