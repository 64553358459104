import { MapItem } from "./mappers";

//export interface ObjectMapper {
//  typeName: string;
//  type: any;
//  mappers: Array<MapItem>
//}

export class ObjectMapper {
  public mappers: Array<MapItem> = [];
  public derived: Array<string> = [];

  constructor(
    public readonly source: Function,
    public readonly dest: Function
  ) { }

  public forMember(rule: MapItem): ObjectMapper {
    this.mappers.push(rule);
    return this;
  }

  public withChild(type: Function): void {
    this.derived.push(type.name);
  }
}
