import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'pipes/pipes.module';

import { DeviceComponent } from './device/device.component';
import { HistoryDeviceComponent } from './history-device/history-device.component';
import { ParkedComponent } from './parked/parked.component';

@NgModule({
  declarations: [
    DeviceComponent,
    HistoryDeviceComponent,
    ParkedComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    ParkedComponent,
    DeviceComponent,
    HistoryDeviceComponent
  ]
})
export class SharedComponentsModule { }
