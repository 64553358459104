import { SelectedMultiple, SelectedSingle } from "../models/selected-menu";
import { MenuCriteria } from "./criteria";

declare module 'modules/devices/components-shared/filters/data/criteria' {
  interface MenuCriteria {
    serialize(): string;
    deserialize(json: string): void;
  }
}

MenuCriteria.prototype.serialize = function (this: MenuCriteria) {
  return JSON.stringify(this);
};

MenuCriteria.prototype.deserialize = function (this: MenuCriteria, json: string) {
  const clouds = JSON.parse(json);
  if (clouds && Array.isArray(clouds)) {
    this.clouds = clouds.filter(item => item.items || item.selected).map(item => item.items ? new SelectedMultiple(item.name, item.items) : new SelectedSingle(item.name, item.selected));
  }
}
