import { Device } from "modules/devices/models/device";

export class Filter {
  private criterias: Array<Func> = new Array<Func>();

  public add(criteria: Func): void {
    this.criterias.push(criteria);
  }

  public empty(): boolean {
    return this.criterias.empty();
  }

  public inCriterias(device: Device): boolean {
    return this.criterias.every(criteria => criteria.call(this, device));
  }
}
