import { Position } from "./position.class";
import { isNull } from "app/modules/shared/data/utils";

declare module './position.class' {
  interface Position {
    parse(positionString: string): Position;
    asString(): string;
  }
}

Position.prototype.parse = function (positionString: string): Position {
  if (isNull(positionString)) new Position();
  const array: Array<string> = positionString.split(',');
  if (array.length !== 2)
    return new Position();

  return new Position(Number(array[0]) || 0, Number(array[1]) || 0);
};

Position.prototype.asString = function (this: Position): string {
  return `${this.x},${this.y}`;
};
