import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationDesktopModule } from './desktop/navigation-desktop.module';
import { NavigationMobileModule } from './mobile/navigation-mobile.module';

@NgModule({
  imports: [
    CommonModule,
    NavigationDesktopModule,
    NavigationMobileModule
  ],
  exports: [
    NavigationDesktopModule,
    NavigationMobileModule
  ]
})
export class NavigationModule { }
