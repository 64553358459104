import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AlertEvent } from 'modules/shared/models/events';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();

  public getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  public error(text: any): void {
    //console.error(text);
    const message = typeof text === 'string' && !(/^(<([^>]+)>)/.test(text)) ? text : 'Произошла ошибка';
    this.subject.next({ type: AlertEvent.OnMessageReceived, value: message });
  }

  public clean(): void {
    this.subject.next({ type: AlertEvent.OnCommandSent });
  }
}
