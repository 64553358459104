import { Component, Injector, ChangeDetectionStrategy } from '@angular/core';

import { HistoryDevice } from 'modules/history/models/history';
import HistoryList from 'modules/history/components-shared/history-list/history-list';
import { NavBarEvent } from 'modules/shared/models/events';

import { NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  templateUrl: './history-list.view.html',
  styleUrls: ['./history-list.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryListView extends HistoryList {
  public get selectedHistory() {
    return this._selectedHistory;
  }
  public set selectedHistory(history: HistoryDevice) {
    this._selectedHistory = history;
    this.changeDetector.detectChanges();
    this.navbarService.emit({ type: NavBarEvent.OnStatusbarTextChanged, value: history.name });
  }

  private _selectedHistory: HistoryDevice;

  constructor(
    injector: Injector,
    private readonly navbarService: NavbarCommService
  ) { super(injector); }
}
