import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

import { HistoryFeatureDevice } from 'modules/map/data/history';
import { HistoryEvent } from 'modules/shared/models/events';
import { ShortDevice } from 'modules/history/models/short-device';

import { HistoryCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'selected-history-panel',
  templateUrl: './selected-history.panel.html',
  styleUrls: ['./selected-history.panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedHistoryPanel {
  @Input() public coordinates: Array<ShortDevice>;
  public get selected() {
    return this._selected;
  }
  public set selected(index: number) {
    this._selected = index;
    this.changeDetector.detectChanges();
  }

  @ViewChild(CdkVirtualScrollViewport) public viewPort: CdkVirtualScrollViewport;

  private _selected: number;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly historyService: HistoryCommService
  ) { }

  public ngOnInit(): void {
    this.historyService.on(HistoryEvent.OnHistoryItemChoosen, (historyDevice: HistoryFeatureDevice): void => {
      this.viewPort.scrollToIndex(historyDevice.index);
      this.selected = historyDevice.index;
      setTimeout(() => this.selected = undefined, 500);
    });
  }

  public onHistoryItemClicked(device: ShortDevice): void {
    this.historyService.emit({ type: HistoryEvent.OnListSelected, value: device });
  }
}
