import { Common, Firefox, Other, Edge, ScreenBase } from './full-screen';

export const screenModes = [{
  enableFunc: 'requestFullscreen',
  disableFunc: 'exitFullscreen',
  type: Common
}, {
  enableFunc: 'mozRequestFullScreen',
  disableFunc: 'mozCancelFullScreen',
  type: Firefox
}, {
  enableFunc: 'webkitRequestFullscreen',
  disableFunc: 'webkitExitFullscreen',
  type: Other
}, {
  enableFunc: 'msRequestFullscreen',
  disableFunc: 'msExitFullscreen',
  type: Edge
}];
