import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GeoRect, HistoryPeriod } from '../models/history';
import { HistoryDevice } from '../models/history';
import { HistoryResponse } from '../models/history.response';

import { Mapper } from 'modules/shared/mapper/mapper';
import { nameof } from 'app/modules/shared/data/utils';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    @Inject('BASE_API_URL') private readonly baseUrl: string,
    private readonly mapper: Mapper,
    private readonly http: HttpClient
  ) { this.baseUrl += '/history' }

  /**
   * Запрос истории передвижения объектов
   * @param devices {string} Выбранные устройства, через запятую.
   * @param period {HistoryPeriod} Период запроса истории.
   * @param geoRect {GeoRect} Область карты.
   * @param activity {boolean} Получить только валидные координаты.
   * @param limit {number} Кол-во запрашиваемых точек истории.
   * @returns {Array<HistoryDevice>} Преобразованный класс истории перемещения устройства.
   */
  public getHistory(devices: string, period: HistoryPeriod, geoRect: GeoRect, activity: boolean, limit: number): Observable<HistoryDevice[]> {
    return this.http.get<HistoryResponse[]>(`${this.baseUrl}?devices=${devices}${period.getUri()}${geoRect.getUri()}&activity=${activity}&limit=${limit}`)
      .pipe(map((response: HistoryResponse[]): HistoryDevice[] => this.mapper.map(nameof(HistoryResponse), response)));
  }
}
