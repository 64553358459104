import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { animate, transition, trigger, state, style } from '@angular/animations';

import { AlertService } from "core/services/alert.service";

import { AlertEvent, Event } from 'modules/shared/models/events';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('void', style({ height: 0, padding: 0 })),
      transition('void<=> *', animate(500))
    ])
  ]
})

export class AlertComponent implements OnInit, OnDestroy {
  public messages = [];

  private subscription$: Subscription;

  constructor(
    private readonly alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.subscription$ = this.alertService
      .getAlert()
      .subscribe((event: Event<AlertEvent>) => {
        switch (event.type) {
          case AlertEvent.OnMessageReceived:
            this.messages.insertUnique(event.value);
            break;
          case AlertEvent.OnCommandSent:
            this.messages.removeAll();
            break;
        }        
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public onAlertClose(text: string): void {
    this.messages.remove(text);
  }
}
