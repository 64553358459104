import { HistoryPeriod, PeriodsHelper } from "modules/history/models/history";

const today = new Date(new Date().setHours(0, 0, 0, 0));
const tomorrow = new Date(new Date().setHours(24, 0, 0, 0));
const yesterday = new Date(new Date().setHours(-24, 0, 0, 0));
const dayOfWeek = today.getDay() > 0 ? today.getDay() - 1 : 6;
const msOfWeek = dayOfWeek * 86400000;
const msWeek = 7 * 86400000;

export const historyPeriods: Array<PeriodsHelper> = [
  { name: 'За сегодня', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(today.getTime()), new Date(tomorrow.getTime())) },
  { name: 'За текущую неделю', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(today.getTime() - msOfWeek), new Date(today.getTime() - msOfWeek + msWeek)) },
  { name: 'За текущий месяц', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth() + 1, 1)) },
  { name: 'За прошедшие сутки', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(yesterday.getTime()), new Date(today.getTime())) },
  { name: 'За прошедшую неделю', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(today.getTime() - msOfWeek - msWeek), new Date(today.getTime() - msOfWeek)) },
  { name: 'За прошедший месяц', getPeriod: (): HistoryPeriod => new HistoryPeriod(new Date(new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime()), new Date(new Date(today.getFullYear(), today.getMonth(), 1).getTime())) }
];
