import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  public transform(value: number, fixed: number = 2): any {
    if (!Number(value) || value.toString().split('.').pop().length === 1) return value;
    if (Number.isInteger(value)) return value.toFixed(1);
    
    return value.toFixed(fixed);
  }
}
