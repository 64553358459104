import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './time-machine.view.html',
  styleUrls: ['./time-machine.view.scss']
})
export class TimeMachineView implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
