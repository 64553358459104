import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { CdkDrag, CdkDragEnd } from '@angular/cdk/drag-drop';

import { Position } from 'modules/navigation/desktop/data/position.class';
import { ResizeElementDirection, ResizeElementEvent } from 'modules/navigation/desktop/data/resizes';
import { Size } from 'modules/navigation/desktop/data/size.class';

import { NavLink, navLinks } from 'modules/navigation/data/nav-links';

import { SettingsService } from 'core/services/settings.service';

@Component({
  selector: 'nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss']
})
export class NavPanelComponent {
  public readonly AngularResizeElementDirection = ResizeElementDirection;
  public readonly links: Array<NavLink> = navLinks;
  public size: Size = new Size();

  @ViewChild("card", { read: CdkDrag, static: true }) card: any;
  @ViewChild("widthCalc", { static: true }) widthCalc: ElementRef;

  constructor(
    private readonly settingsService: SettingsService
  ) { }

  ngAfterContentInit(): void {
    this.settingsService.trySet(Position.key, (value: any) => this.card._dragRef.setFreeDragPosition(Position.prototype.parse(value)));
    this.settingsService.trySet(Size.key, (value: any) => {
      const position = this.card.getFreeDragPosition();
      const size = Size.prototype.parse(value);

      this.resetSize(
        Math.min(size.height, window.innerHeight - position.y),
        Math.min(size.width, window.innerWidth - position.x)
      );
    });
    this.card._dragRef.beforeStarted.next();
  }

  public onDragEnded(event: CdkDragEnd): void {
    const position = this.card.getFreeDragPosition();
    this.settingsService.save(Position.key, new Position(position.x, position.y).asString());
  }

  public onResize(event: ResizeElementEvent): void {
    const position = this.card.getFreeDragPosition();
    this.resetSize(
      event.currentHeightValue + position.y <= window.innerHeight ? event.currentHeightValue : window.innerHeight - position.y,
      event.currentWidthValue + position.x <= window.innerWidth ? event.currentWidthValue : window.innerWidth - position.x
    );
  }

  public onResizeEnd(event: ResizeElementEvent): void {
    this.settingsService.save(Size.key, this.size.asString());
  }

  private resetSize(y: number, x: number): void {
    this.size = new Size(
      8 * (this.widthCalc.nativeElement.clientHeight + 1),
      240 + (this.widthCalc.nativeElement.clientWidth + 1),
      y,
      x
    );
  }
}
