import { Component, Output, EventEmitter, Injector } from '@angular/core';

import { DeviceSelector } from '../../data/device-selector';
import { HistoryDevice } from 'modules/history/models/history';

@Component({
  selector: 'device-selector-menu',
  templateUrl: './device-selector.menu.html',
  styleUrls: ['./device-selector.menu.scss']
})
export class DeviceSelectorMenu extends DeviceSelector {

  @Output() public onHistorySelected: EventEmitter<HistoryDevice> = new EventEmitter();

  constructor(injector: Injector) { super(injector); }
}
