import { Component, OnInit, ViewChild, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Feature } from "ol/Feature";
import { VectorLayer } from "ol/layer/Vector";
import { Map, Overlay } from 'ol';

import { Device } from 'modules/devices/models/device';
import { DeviceEvent } from 'modules/shared/models/events';
import { MenuAction } from 'modules/map/data/context-menu';
import { DeviceCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'context-menu-overlay',
  templateUrl: './context-menu.overlay.html',
  styleUrls: ['./context-menu.overlay.scss']
})
export class ContextMenuOverlay implements OnInit, AfterViewInit, OnDestroy {
  public menus: Array<MenuAction> = [
    {
      name: 'История', action: (device: Device) => {
        this.router.navigateByUrl('/navigation/history');
        this.deviceService.emit({ type: DeviceEvent.OnDeviceHistory, value: device });
      }
    },
    { name: 'Свойства', action: (device: Device) => this.deviceService.emit({ type: DeviceEvent.OnDeviceDetail, value: device }) }
  ];
  @ViewChild('popup') public popup;
  public popUpOverlay: Overlay;

  private map: Map;
  private selectedDevice: Device;

  constructor(
    private readonly deviceService: DeviceCommService,
    private readonly mapService: OlMapService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.map = this.mapService.getMap();
    this.map.getViewport().addEventListener('contextmenu', (event: MouseEvent) => {
      const feature = this.map.forEachFeatureAtPixel(this.map.getEventPixel(event), (feature: Feature, layer: VectorLayer) => feature);
      const device: Device = feature?.get('device');
      if (device) {
        this.selectedDevice = device;
        this.popUpOverlay.setPosition(device.getValidCoordinate());
      }
      event.preventDefault();
    });

    this.map.on('movestart', event => this.selectedDevice && this.popUpOverlay.setPosition(undefined) && (this.selectedDevice = undefined));
  }

  ngAfterViewInit(): void {
    this.popUpOverlay = new Overlay({
      element: this.popup.nativeElement,
      offset: [10, -35]
    });
    this.map.addOverlay(this.popUpOverlay);
  }

  ngOnDestroy(): void {
    this.map.removeOverlay(this.popUpOverlay);
  }

  public onMenuClick(action: Action): void {
    action?.call(this, this.selectedDevice);
    this.popUpOverlay.setPosition(undefined);
  }
}
