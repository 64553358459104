import { Navigation } from "../models/navigation";
import { fromLonLat } from 'ol/proj';
import { notNull } from "app/modules/shared/data/utils";

declare module 'modules/shared/models/navigation' {
  interface Navigation {
    /**
     * Проверка наличия последних координаты.
     * @returns Результат проверки.
     * */
    hasCoordinate(): boolean;

    /**
     * Получение координаты.
     * @returns Массив координат сконвертированных "ol/proj/fromLonLat" или null.
     * */
    getCoordinate(): Array<number> | null;
  }
}

Navigation.prototype.hasCoordinate = function (this: Navigation): boolean {
  return notNull(this.longitude) && notNull(this.latitude);
};

Navigation.prototype.getCoordinate = function (this: Navigation): Array<number> | null {
  return this.hasCoordinate() ? fromLonLat([this.longitude, this.latitude]) : null;
};
