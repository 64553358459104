import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { DeviceEvent, MobileEvent, NavBarEvent } from 'modules/shared/models/events';

import { DeviceCommService, MobileCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'sidebar-button',
  templateUrl: './sidebar.button.html',
  styleUrls: ['./sidebar.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarButton implements OnInit {
  public get isSidebarShown() {
    return this._isSidebarShown;
  }
  public set isSidebarShown(value: boolean) {
    this._isSidebarShown = value;
    this.changeDetector.detectChanges();
  }
  public get orientation() {
    return this._orientation;
  }
  public set orientation(value: Orientation) {
    this._orientation = value;
    this.changeDetector.detectChanges();
  }

  private _isSidebarShown: boolean = true;
  private _orientation: Orientation = screen.availHeight > screen.availWidth ? 'portrait' : 'landcape';

  constructor(
    private readonly mobileService: MobileCommService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly deviceService: DeviceCommService,
    private readonly navbarService: NavbarCommService
  ) { }

  ngOnInit(): void {
    this.mobileService.on(MobileEvent.OnOrientationChanged, (value: Orientation) => this.orientation = value);
    this.deviceService.on(DeviceEvent.OnDeviceChoosen, () => this.isSidebarShown = false);
    this.navbarService.on(NavBarEvent.OnSidebarToggled, (value: boolean) => this.isSidebarShown = value);
  }

  public onSideBarToggle(): void {
    this.navbarService.emit({ type: NavBarEvent.OnSidebarToggled, value: !this.isSidebarShown });
  }

}
