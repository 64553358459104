export interface CheckBoxItem {
  text: string;
  isCheck: boolean;
}

export interface CloudView {
  name: string;
  item: string;
}

export class CloudSetting {
  public value: string;

  constructor(
    public readonly key: string
  ) { }
}
