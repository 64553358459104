import Circle from 'ol/style/Circle';
import Feature from "ol/Feature";
import Map from 'ol/Map';
import Point from "ol/geom/Point";
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as olExtent from 'ol/extent';

import { easeOut as ol_easeOut } from 'ol/easing'
import { unByKey } from "ol/Observable";

import featureZoom from './zoom';
import { isNull } from 'app/modules/shared/data/utils';

const duration = 200;

Map.prototype.toCenter = function (this: Map, coordinates: Array<number>): void {
  this.getView().animate({
    center: coordinates,
    duration: duration,
    //zoom: 15
  })
};

Map.prototype.zoomToFit = function (this: Map, coordinates: Array<Array<number>>): void {
  if (coordinates) {
    const view = this.getView();
    const extent = olExtent.boundingExtent(coordinates);
    view.fit(extent, { padding: [20, 20, 20, 20], maxZoom: 15, duration: duration });
  }
};

Map.prototype.zoomToFit = function (this: Map, feature: Feature): void {
  if (feature) {
    const view = this.getView();
    const featureGeometry = feature.getGeometry();
    view.fit(featureGeometry, { padding: [20, 20, 20, 20], maxZoom: 15, duration: duration });
  }
};

Map.prototype.zoomIn = function (this: Map, zoom: number = .33): void {
  const view = this.getView();
  view.animate({
    zoom: view.getZoom() + zoom,
    duration: duration
  });
};

Map.prototype.zoomOut = function (this: Map, zoom: number = .33): void {
  const view = this.getView();
  view.animate({
    zoom: view.getZoom() - zoom,
    duration: duration
  });
};

Map.prototype.animateFeature = function (coordinate: Array<number>, count: number = 3) {
  for (let index = 0; index < count; index++) {
    setTimeout(() => this.animateFeatureOnce(coordinate), index * 250);
  }
};

Map.prototype.animateFeatureOnce = function (coordinate: Array<number>): void {
  const feature = new Feature(new Point(coordinate));
  feature.setStyle(new Style({
    image: new Circle({
      radius: 30, //max radius
      stroke: new Stroke({ color: 'red', width: 2 })
    })
  }));

  const fanim = new featureZoom({
    fade: ol_easeOut,
    duration: 500,
    easing: ol_easeOut
  });

  // Get or create an animation layer associated with the map
  let layer = this._featureAnimationLayer;
  if (isNull(layer)) {
    layer = this._featureAnimationLayer = new VectorLayer({ source: new VectorSource() });
    layer.setMap(this);
  }
  // Animate feature on this layer
  layer.getSource().addFeature(feature);
  let listener = fanim.on('animationend', function (e) {
    if (e.feature === feature) {
      // Remove feature on end
      layer.getSource().removeFeature(feature);
      unByKey(listener);
    }
  });
  layer.animateFeature(feature, fanim);
};
