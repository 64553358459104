import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

///<reference path="./typings/app.d.ts"/>
///<reference path="./typings/global.d.ts"/>
import './index';

const baseHref = (document.getElementsByTagName('base')[0] || {}).href;
const providers = [
  { provide: 'BASE_API_URL', useValue: `${baseHref}api` }
];

//console.log(environment);

if (environment.production) {
  alert('prod');
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
