import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { formatDate } from '@angular/common';

import Feature from "ol/Feature";
import { Select } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import { Map as OlMap } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from "ol/layer/Vector";
import { Cluster, Vector as VectorSource } from "ol/source";
import { Style, Fill, Circle, Text, Stroke } from "ol/style";
import { pointerMove } from 'ol/events/condition';

import { HistoryDevice } from 'modules/history/models/history';
import { HistoryFeatureDevice } from 'modules/map/data/history';
import { MapEvent, HistoryEvent } from 'modules/shared/models/events';
import { NavView } from 'modules/map/models/nav-view';
import { SettingKeys } from 'core/data/settings';
import { ShortDevice } from 'modules/history/models/short-device';

import { decimalToHex, parseBoolean, isNull } from 'app/modules/shared/data/utils';
import { createSvgStyle, addTooltip, createPointsClusterStyle } from 'modules/map/data/ol-map-utils';

import { HistoryCommService, MapCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from 'modules/map/services/ol-map.service';
import { SettingsService } from 'core/services/settings.service';

@Component({
  selector: 'history-points-layer'
})
export class HistorPointsLayer implements OnInit {
  private clusterSource: Cluster;
  private map: OlMap;
  private historyLayer: VectorLayer;
  private historySource: VectorSource = new VectorSource();
  private view: NavView;

  private isUsedClusters: boolean;
  private isUsedTooltips: boolean;
  private history: Array<HistoryDevice> = [];

  constructor(
    private readonly olMapService: OlMapService,
    private readonly historyService: HistoryCommService,
    private readonly settingsService: SettingsService,
    private readonly mapService: MapCommService
  ) { }

  ngOnInit(): void {
    this.map = this.olMapService.getMap();
    this.historyLayer = new VectorLayer({ name: 'historyPointsLayer', source: this.historySource });
    this.map.addLayer(this.historyLayer);
    this.view = this.map.getView();

    this.map.on('click', event => {
      this.map.forEachFeatureAtPixel(event.pixel, (clusterFeature: Feature, layer: VectorLayer): void => {

        if (this.isUsedClusters) {
          const features: Array<Feature> = clusterFeature.get('features');
          if (features?.length !== 1) return;
          clusterFeature = features.first();
        }
        const device = clusterFeature.get('device');
        device && this.historyService.emit({ type: HistoryEvent.OnMapSelected, value: clusterFeature.get('device') });
      });
    });

    this.settingsService.trySet(SettingKeys.HISTORY_CLUSTER, (value: string) => this.isUsedClusters = parseBoolean(value));
    this.settingsService.trySet(SettingKeys.HISTORY_TOOLTIP, (value: string) => this.isUsedTooltips = parseBoolean(value));

    this.mapService.on(MapEvent.OnGotHistory, (history: Array<HistoryDevice>): void => {
      this.history = history;
      this.createFeatures();
      this.map.getView().fit(this.historySource.getExtent(), this.map.getSize());
    });
    this.mapService.on(MapEvent.OnHistoryCleared, (): void => {
      this.historySource.clear();
      this.history.removeAll();
    });
    this.mapService.on(MapEvent.OnHistoryClusterToggled, (isClustered: boolean): void => {
      this.isUsedClusters = isClustered;
      this.historyLayer.setSource(this.createSource());
    });
    this.mapService.on(MapEvent.OnHistoryTooltipToggled, (isTooltiped: boolean): void => {
      this.isUsedTooltips = isTooltiped;
      this.createFeatures();
    });

    this.historyService.on(HistoryEvent.OnListSelected, (device: ShortDevice): void => {
      if (device.hasLastCoordinate() === false) return;
      const coordinate = device.getLastCoordinate();
      this.map.toCenter(coordinate);
      this.map.animateFeature(coordinate);
      this.isUsedClusters && this.historyService.emit({ type: HistoryEvent.OnItemShownOnMap, value: device });
    });

    this.historyService.on(HistoryEvent.OnHistoryDeleted, (device: HistoryDevice): void => { });
  }

  private createFeatures(): void {
    const features: Array<Array<Feature>> = this.history.map((history: HistoryDevice): Array<Feature> =>
      history.coordinates.map((device: ShortDevice, index: number): Feature => {
        //const coordinate = fromLonLat([device.lastNav.longitude, device.lastNav.latitude]);
        const pointFeature = new Feature(new Point(device.getLastCoordinate()));
        pointFeature.set('device', { id: history.id, index, color: history.color, device: device });
        pointFeature.setStyle(this.createStyle(pointFeature));
        return pointFeature;
      }));
    this.historySource = new VectorSource({ features: features.flatten() });
    this.historyLayer.setSource(this.createSource());
  }

  private createStyle(feature: Feature): Style {
    const historyDevice: HistoryFeatureDevice = feature.get('device');
    const style = createSvgStyle(historyDevice.device.mark);
    this.isUsedTooltips && addTooltip(style, `№${historyDevice.index + 1}   ${historyDevice.device.lastNav.time.toShortDateTime()}`);
    return style;
  }

  private createSource(): Cluster | VectorSource {
    if (this.isUsedClusters) {
      this.historyLayer.setStyle(createPointsClusterStyle.bind(this));
      this.clusterSource = new Cluster({ distance: 48, source: this.historySource });
      return this.clusterSource;
    } else {
      this.historyLayer.setStyle(undefined);
      return this.historySource;
    }
  }
}
