import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from 'ng-pick-datetime';

@Injectable()
export class NavIntl extends OwlDateTimeIntl {
  constructor() {
    super();

    this.setBtnLabel = 'Выбрать';
    this.cancelBtnLabel = 'Отмена';
  }
}
