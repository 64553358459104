import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeMachineDesktopModule } from './desktop/time-machine-desktop.module';
import { TimeMachineMobileModule } from './mobile/time-machine-mobile.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TimeMachineDesktopModule,
    TimeMachineMobileModule
  ],
  exports: [
    TimeMachineDesktopModule,
    TimeMachineMobileModule
  ]
})
export class TimeMachineModule { }
