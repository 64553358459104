import { Injector, Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";

import { GeoRect, HistoryPeriod, HistoryDevice } from "modules/history/models/history";
import { MapCommService } from 'modules/shared/services/communication.service';
import { MapEvent } from 'modules/shared/models/events';

import { AlertService } from 'core/services/alert.service';
import { HistoryDataService } from 'modules/history/services/history-data.service';
import { HistoryService } from 'modules/history/services/history.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class HistoryQuery {
  public devices: string = '';
  public geoRect: GeoRect = new GeoRect();
  public isDataGot: boolean;
  public activity: boolean = false;
  public isRequesting: boolean;
  public period: HistoryPeriod = new HistoryPeriod();
  protected limit: number = 20000;

  protected readonly alertService: AlertService;
  protected readonly historyService: HistoryService;
  protected readonly historyDataService: HistoryDataService;
  protected readonly mapService: MapCommService;
  protected readonly route: ActivatedRoute;
  protected readonly router: Router;

  private historySubscription$: Subscription;

  constructor(injector: Injector) {
    this.alertService = injector.get(AlertService);
    this.historyService = injector.get(HistoryService);
    this.historyDataService = injector.get(HistoryDataService);
    this.mapService = injector.get(MapCommService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
  }

  public ngOnDestroy(): void {
    this.historySubscription$?.unsubscribe();
  }

  public onHistoryClick(): void {
    this.historySubscription$ = this.historyService.getHistory(this.devices, this.period, this.geoRect, this.activity, this.limit)
      .subscribe(
        (history: HistoryDevice[]): void => {
          if (history.empty() === false) {
            this.isDataGot = true;
            this.historyDataService.history = history;
            this.mapService.emit({ type: MapEvent.OnGotHistory, value: history });
            this.router.navigate(['../list'], { relativeTo: this.route, skipLocationChange: true });
          } else {
            this.alertService.error('По Вашему запросу данных не найдено');
            this.isRequesting = false;
          }
        },
        (error: any): void => {
          this.alertService.error(error);
          this.isRequesting = false;
        }
      );
  }

  public onRequestCancelled(): void {
    this.historySubscription$.unsubscribe();
  }
}
