import { Component, Inject } from '@angular/core';

import { SearchCriteria } from 'modules/devices/components-shared/filters/data/criteria';
import { FilterService } from 'modules/devices/components-shared/filters/services/filter.service';

@Component({
  selector: 'nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss']
})
export class NavFooterComponent {

  constructor(
    private readonly filterService: FilterService
  ) { }

  public onSearchBoxTextChanged(text: string): void {
    this.filterService.onCriteriaChanged(new SearchCriteria(text));
  }
}
