import Feature from "ol/Feature";
import { Device } from "modules/devices/models/device";
import { updateByProps, isNull, notNull } from "app/modules/shared/data/utils";

Feature.prototype.setVisible = function (isShow: boolean) {
  this.getStyle()?.getImage().setOpacity(isShow);
};

Feature.prototype.updateDevice = function (this: Feature, device: Device) {
  if (isNull(device)) return;
  if (notNull(this.get('device'))) {
    updateByProps(this.get('device'), device);
  } else {
    this.set('device', device);
  }
};
