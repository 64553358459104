import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';

import { NavLink, navLinks } from 'modules/navigation/data/nav-links';
import { NavBarEvent } from 'modules/shared/models/events';

@Component({
  selector: 'mob-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  public readonly links: Array<NavLink> = navLinks;
  public readonly navBarEvent = NavBarEvent;

  constructor() { }
}
