import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceFilterPipe } from './device.filter.pipe';
import { RoundPipe } from './round.pipe';

@NgModule({
  declarations: [
    DeviceFilterPipe,
    RoundPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DeviceFilterPipe,
    RoundPipe
  ]
})
export class PipesModule { }
