import { Component, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy } from '@angular/core';

import { DeviceEvent, NavBarEvent } from 'modules/shared/models/events';
import { notNull } from 'modules/shared/data/utils';

import { DeviceCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent implements OnInit {
  public get isPropsShown() {
    return this._isPropsShown;
  }
  public set isPropsShown(value: boolean) {
    this._isPropsShown = value;
    this.changeDetector.detectChanges();
  }

  private _isPropsShown: boolean = false;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly deviceService: DeviceCommService
  ) { }

  ngOnInit(): void {
    this.deviceService.on(DeviceEvent.OnDeviceDetail, (device: any) => this.isPropsShown = notNull(device));
  }
}
