import { Component, OnInit, Input } from '@angular/core';

import { MapEvent } from 'modules/shared/models/events';
import { parseBoolean } from 'modules/shared/data/utils';

import { MapCommService } from 'modules/shared/services/communication.service';
import { SettingsService } from 'core/services/settings.service';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle.button.html',
  styleUrls: ['./toggle.button.scss']
})
export class ToggleButton implements OnInit {
  public isPressed: boolean;

  @Input() public readonly iconPressed: string;
  @Input() public readonly iconUnpressed: string;
  @Input() public readonly mapEvent: MapEvent;
  @Input() public readonly settingKey: string;
  @Input() public readonly title: string;

  constructor(
    private readonly mapCommService: MapCommService,
    private readonly settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService.trySet(this.settingKey, (value: string) => this.isPressed = parseBoolean(value));
  }

  public onClicked(): void {
    this.mapCommService.emit({ type: this.mapEvent, value: this.isPressed });
    this.settingsService.save(this.settingKey, Number(this.isPressed).toString());
  }
}
