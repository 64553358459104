import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { Event, DeviceEvent, MapEvent, HistoryEvent, AppEvent, CloudEvent, NavBarEvent, MobileEvent } from '../models/events';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService<TEvent> {
  private subject$ = new Subject();
  private subscriptions: Array<Subscription> = [];

  constructor() { }

  /**
   * Инициирование события.
   * @param event Типизироанное событие.
   */
  public emit(event: Event<TEvent>): void {
    this.subject$.next(event);
  }

  /**
   * Подписка на событие.
   * @param raisedEvent Типизированное событие
   * @param action Обработчик.
   */
  public on(raisedEvent: TEvent, action: Action): void {
    const subscription = this.subject$
      .pipe(
        filter((event: Event<TEvent>) => event.type === raisedEvent),
        map((event: Event<TEvent>) => event.value)
      ).subscribe(action);
    this.subscriptions.push(subscription);
  }

  /**
   * Отписка от всех подписок.
   */
  public dispose(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppCommService extends CommunicationService<AppEvent> { }

@Injectable({
  providedIn: 'root'
})
export class CloudCommService extends CommunicationService<CloudEvent> { }

@Injectable({
  providedIn: 'root'
})
export class DeviceCommService extends CommunicationService<DeviceEvent> { }

@Injectable({
  providedIn: 'root'
})
export class MapCommService extends CommunicationService<MapEvent> { }

@Injectable({
  providedIn: 'root'
})
export class MobileCommService extends CommunicationService<MobileEvent> { }

@Injectable({
  providedIn: 'root'
})
export class HistoryCommService extends CommunicationService<HistoryEvent> { }

@Injectable({
  providedIn: 'root'
})
export class NavbarCommService extends CommunicationService<NavBarEvent> { }
