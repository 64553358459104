import { Component, OnInit, Inject } from '@angular/core';

import { DeviceEvent } from 'modules/shared/models/events';

import { DeviceCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent implements OnInit {
  public isShow: boolean = true;

  constructor(
    private readonly deviceService: DeviceCommService
  ) { }

  ngOnInit(): void {
    this.deviceService.on(DeviceEvent.OnDevicesLoaded, () => this.isShow = false);
  }
}
