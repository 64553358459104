import { SelectedCloud, SelectedSingle, SelectedMultiple } from './selected-menu';
import { CheckBoxItem } from './cloud';

export abstract class Menu {

  constructor(
    public readonly cloudName: string,
    public readonly subMenus: Array<CheckBoxItem> = []
  ) { }

  public abstract onClick(checkBox: CheckBoxItem): void;
  public abstract toSelected(): SelectedCloud
}

export class SingleMenu extends Menu {

  public onClick(checkBox: CheckBoxItem): void {
    this.subMenus.forEach((subMenu: CheckBoxItem) => subMenu.isCheck = subMenu.text === checkBox.text ? checkBox.isCheck : false);
  }

  public toSelected(): SelectedCloud {
    return new SelectedSingle(
      this.cloudName,
      this.subMenus.find((subMenu: CheckBoxItem) => subMenu.isCheck)?.text
    )
  }
}

export class MultipleMenu extends Menu {

  public onClick(checkBox: CheckBoxItem): void { }

  public toSelected(): SelectedCloud {
    return new SelectedMultiple(
      this.cloudName,
      this.subMenus.filter((subMenu: CheckBoxItem) => subMenu.isCheck).map((subMenu: CheckBoxItem) => subMenu.text)
    );
  }
}
