import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersModule } from './filters/filters.module';

@NgModule({
  imports: [
    CommonModule,
    FiltersModule
  ],
  exports: [
    FiltersModule
  ]
})
export class ComponentsSharedModule { }
