import { ElementRef, ViewChild, ContentChildren, QueryList } from "@angular/core";

export class PanelBase {
  @ViewChild('panel')
  private readonly panel: ElementRef<HTMLElement>;

  @ContentChildren('control', { read: ElementRef }) controls: QueryList<ElementRef>;

  protected addControls(): void {
    this.controls.forEach((control: ElementRef): void => this.panel.nativeElement.appendChild(control.nativeElement));
  }
}
