import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

import { DeviceEvent, MobileEvent, NavBarEvent } from 'modules/shared/models/events';
import { NavBase } from 'modules/navigation/data/nav-base';

import { DeviceCommService, MobileCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'mob-navigation',
  templateUrl: './navigation.page.html',
  styleUrls: ['./navigation.page.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
  //encapsulation: ViewEncapsulation.None //Для применения стилей к навбару и её дочерних компонентов.
})
export class NavigationPage extends NavBase implements OnInit {
  public isSideBarShown : boolean;
  public isPortrait: boolean = screen.availHeight > screen.availWidth;
  public events: Array<any> = [];

  constructor(
    private readonly mobileService: MobileCommService,
    private readonly deviceService: DeviceCommService,
    private readonly navbarService: NavbarCommService
  ) { super(); }

  public ngOnInit(): void {
    this.onResize();  
    this.deviceService.on(DeviceEvent.OnDeviceChoosen, () => this.isSideBarShown = false);
    this.navbarService.on(NavBarEvent.OnSidebarToggled, (value: boolean) => this.isSideBarShown = value);
  }

  public onResize(): void {
    this.isPortrait = screen.availHeight > screen.availWidth;
    this.mobileService.emit({ type: MobileEvent.OnOrientationChanged, value: this.isPortrait ? 'portrait' : 'landcape' });
    window.scrollTo(0, 1);
  }
}
