import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './data/custom-reuse-strategy';

import { AppRoutingModule } from 'modules/app-routing/app-routing.module';
import { HammerConfig } from './data/hammer-config';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { InitializeModule } from './initialize.module';
import { StorageServiceModule } from "ngx-webstorage-service";
//import { CookieService } from "ngx-cookie-service";

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    HammerModule,
    InterceptorsModule,
    InitializeModule,
    StorageServiceModule
  ],
  providers: [
    //CookieService,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
  ]
})
export class CoreModule { }
