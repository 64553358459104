import { Injectable, Inject } from '@angular/core';

import { Criteria } from '../data/criteria';
import { DeviceEvent } from 'modules/shared/models/events';
import { Filter } from '../data/filter.class';
import { nameof } from 'app/modules/shared/data/utils';

import { DeviceCommService } from 'modules/shared/services/communication.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private criterias: Array<Criteria> = []

  constructor(
    private readonly deviceService: DeviceCommService
  ) { }

  public onCriteriaChanged(criteria: Criteria): void {
    this.criterias = this.criterias.filter((savedCriteria: Criteria): boolean => nameof(savedCriteria) !== nameof(criteria));
    this.criterias.push(criteria);
    this.notify();
  }

  private notify(): void {
    const filter = new Filter();
    this.criterias.forEach((criteria: Criteria): void => criteria.addCriteria(filter));
    this.deviceService.emit({ type: DeviceEvent.OnFilterChanged, value: filter });
  }
}
