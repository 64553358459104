import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'request-valids',
  templateUrl: './request-valids.component.html',
  styleUrls: ['./request-valids.component.scss']
})
export class RequestValidsComponent {
  public isRequestValids: boolean;

  @Output() public onParamChanged: EventEmitter<boolean> = new EventEmitter();

  constructor() { }
}
