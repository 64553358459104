import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Router } from '@angular/router';

import { AuthenticationService } from "modules/authorization/services/authentication.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class Logout {

  constructor(
    protected readonly authService: AuthenticationService,
    private readonly router: Router
  ) { }

  public logout(): void {
    this.authService.logout().subscribe();
    this.authService.clear();
    this.router.navigateByUrl('/');
  }
}
