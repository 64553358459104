export class ShortDeviceResponse {
  /* время координаты */
  public CTm: string;
  /* широта */
  public CLat: number;
  /* долгота */
  public CLon: number;
  /* азимут (курс) */
  public CCrs: number;
  /* скорость */
  public CSpd: number;
  /* NavStatus (полный байт состояния навигации и канала) */
  public CNStt: number;
  /* Канал передачи данных (часть от NavStatus) */
  public Ch: number;
  /* Название канала передачи данных */
  public ChDsc: string;
  /* Состояние навигации (часть от NavStatus; 0-достоверно, не 0-не достоверно) */
  public N: number;
  /* Описание состояния навигации */
  public NDsc: string;
  /* Состояние устройств (часть от NavStatus) */
  public E: number;
  /* Описание состояния устройств (антена, gsm) */
  public EDsc: string;
  /* Состояние экипажа (внешнее состояние), Сооrdinate state description */
  public CEStt: string;
  /* Режим работы (часть CEStt) */
  public Md: number;
  /*Название режима работы */
  public MdDsc: string;
  /* Источник питания (часть CEStt) */
  public Pwr: number;
  /* Название источника питания */
  public PwrDsc: string;
  /* Описание состояния внешних устройств */
  public RlDsc: string;
  /* Описание состояния тревоги */
  public AlDsc: string;
  /* Описание ещё какого-то состояния */
  public CuDsc: string;
  /* Описание состояния внешних шлейфов */
  public WrDsc: string;
  /* Описание состояния охраны */
  public GdDsc: string;
  /* Описание состояния парковки */
  public Pkd: boolean;
  /* Время приёма координаты */
  public RTm: string;
  /* Контекст (JSON; всякие дополнения) */
  public Ctx: string;  //??
  /* Маркер (SVG) */
  public Mark: string;
}
