import ol_Object from 'ol/Object';
import Style from 'ol/style/Style';
import { getVectorContext } from 'ol/render';
import { linear as ol_easing } from 'ol/easing'
import { olInherits, hiddenStyle } from './ol-map-utils';

export class FeatureAnimation extends ol_Object {
  private duration_: number;
  private fade_: Function;
  private repeat_: number;
  private easing_: any;
  private hiddenStyle_: Style;

  constructor(options: any = {}) {
    super();

    this.duration_ = typeof (options.duration) == 'number' ? (options.duration >= 0 ? options.duration : 0) : 1000;
    this.fade_ = typeof (options.fade) == 'function' ? options.fade : null;
    this.repeat_ = Number(options.repeat);
    var easing = typeof (options.easing) == 'function' ? options.easing : ol_easing.linear;
    if (options.revers) this.easing_ = function (t) { return (1 - easing(t)); };
    else this.easing_ = easing;
    this.hiddenStyle_ = options.hiddenStyle || hiddenStyle;
    ol_Object.call(this);
  }

  public drawGeom_(e, geom, shadow) {
    if (this.fade_) {
      e.context.globalAlpha = this.fade_(1 - e.elapsed);
    }
    var style = e.style;
    for (var i = 0; i < style.length; i++) {
      // Prevent crach if the style is not ready (image not loaded)
      try {
        var vectorContext = e.vectorContext || getVectorContext(e);
        vectorContext.setStyle(style[i]);
        if (style[i].getZIndex() < 0) vectorContext.drawGeometry(shadow || geom);
        else vectorContext.drawGeometry(geom);
      } catch (e) { /* ok */ }
    }
  }
}

export default FeatureAnimation;
