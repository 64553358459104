import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { ShortDevice } from 'modules/history/models/short-device';

import { channelIcons, powerIcons, modeIcons, stateNavIcons } from 'modules/shared/data/device-icons';

@Component({
  selector: 'history-device',
  templateUrl: './history-device.component.html',
  styleUrls: ['./history-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryDeviceComponent implements OnInit {
  @Input() public device: ShortDevice;
  @Input() public index: number;

  constructor() { }

  ngOnInit(): void { }

  public get channelIcon() {
    const icon = channelIcons.find(icon => icon.code === this.device.channel.code)?.icon;;
    return this.getIcon(icon);
  }

  public get powerIcon() {
    const icon = powerIcons.find(icon => icon.code === this.device.power.code)?.icon;;
    return this.getIcon(icon);
  }

  public get modeIcon() {
    const icon = modeIcons.find(icon => icon.code === this.device.mode.code)?.icon;;
    return this.getIcon(icon);
  }

  public get speed() {
    return this.device.isValid() ? this.device.lastNav.speed : null;
  }

  public get stateNavIcon() {
    const icon = stateNavIcons.find(icon => icon.code === this.device.stateNav.code)?.icon;
    return this.getIcon(icon);
  }

  private getIcon(name: string): string {
    return name ? `/img/${name}.ico` : '';
  }
}
