export interface Event<T> {
  type: T,
  value?: any
}

export enum AlertEvent {
  OnMessageReceived = 0,
  OnCommandSent
}

export enum CloudEvent {
  OnCloudsReceived = 0,
  OnCloudsSelected,
  OnCloudUnselected
}

export enum RouteEvent {
  OnRoot = 1, //devices
  OnHistory,
  OnTimeMachine
}

export enum AppEvent {
  /** При переходе на страницу авториации. */
  OnLogout = 0,
  /** При изменении маршрута. */
  OnRouteChanged,
}

/**
 * События взаимодействия списка объектов и объектов на карте.
 **/
export enum DeviceEvent {
  OnDeviceReceived = 0,
  OnDeviceChoosen,
  OnDeviceDetail,
  /** При единичном запросе истории текущего выбранного объекта */
  OnDeviceHistory,
  OnFilterChanged,
  OnItemsFiltered,
  OnDevicesLoaded
}

/**
 * События взаимодействия списка объектов истории и объектов истории на карте.
 **/
export enum HistoryEvent {
  /** Объект истории выбран в списке. */
  OnListSelected = 0,
  /** Объект "ol/geom/Point" выбран на карте. */
  OnMapSelected,
  /** При выборе единицы истории. */
  OnHistoryItemChoosen,
  /** При включенных кластерах, добавление временного устройства (Feature), для отображения. */
  OnItemShownOnMap,
  /** При единичном удаление устрова истории. */
  OnHistoryDeleted,
}

/**
 * События взаимодействия карты с UI.
 **/
export enum MapEvent {
  /** Переключение режима рисования */
  OnDrawToggled = 0,
  /** Нарисованния фигура была изменена вне карты */
  OnDrawnChanged,
  /** При сбросе/удалении нарисованной фигуре */
  OnDrawCleared,
  /** При окончании рисования фигуры на карте */
  OnHistoryAreaDrawn,
  /** При получении истории устройств(-а) */
  OnGotHistory,
  /** При единином выборе истории передижении объекта */
  OnHistorySelected,
  //TODO Перенести связанное с историей в HistoryEvent
  /** При удалении всех features истории */
  OnHistoryCleared,
  /** При клике на объекте на карте */
  OnDeviceSelected,
  /** При клике на единице истории на карте */
  OnHistoryItemSelected,
  /** Включение/отключение тултипа на устройствах */
  OnDeviceTooltipToggled,
  /** Включение/отключение тултипа на единице истории */
  OnHistoryTooltipToggled,
  /** Включение/отключение кластеризации точек истории */
  OnHistoryClusterToggled,
  /** При изменении зума карты. */
  OnZoomChanged,
}

/**
 * События возникающее в мобильной версии.
 **/
export enum MobileEvent {
  /** При смене ориентации экрана. */
  OnOrientationChanged,
}
/** События панели управления в мобильной версии. */
export enum NavBarEvent {
  /** При отображении/сокрытии панели запрошенных устройств истории.  */
  OnDeviceSelectorToggled = 0,
  /** При отображении/сокрытии панели фильтров. */
  OnFiltersToggled,
  /** При отображении/сокрытии боковой панели. */
  OnSidebarToggled,
  /** При смене текста в статус баре. */
  OnStatusbarTextChanged,
}
