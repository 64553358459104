import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { CloudEvent } from 'modules/shared/models/events';
import { CloudView } from 'modules/devices/components-shared/filters/models/cloud';

import { CloudCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'selected-clouds',
  templateUrl: './selected-clouds.component.html',
  styleUrls: ['./selected-clouds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedCloudsComponent implements OnInit {
  @Input() public className: string;
  public cloudViews: Array<CloudView> = [];

  constructor(
    private changeDetection: ChangeDetectorRef,
    private readonly cloudService: CloudCommService
  ) { }

  ngOnInit(): void {
    this.cloudService.on(CloudEvent.OnCloudsSelected, (views: Array<CloudView>): void => {
      this.cloudViews = views;
      this.changeDetection.detectChanges();
    });
  }

  public onUnselect(cloud: string, item: string): void {
    this.cloudService.emit({ type: CloudEvent.OnCloudUnselected, value: { name: cloud, item } });
  }
}
