import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from "app/modules/authorization/services/authentication.service";
import { AlertService } from "core/services/alert.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      const error = err.error?.message || err.error || err.statusText;
      switch (err.status) {
        case 400: //bad request
          this.alertService.error(error);
          break;
        case 401: case 403: //unauthorized response returned from api
          this.authenticationService.clear();
          this.router.navigateByUrl('/');
          break;
        case 503: //Service Unavailable
          this.alertService.error("Подключение не устанавливается");
          break;
      }

      return throwError(error);
    }));
  }
}
