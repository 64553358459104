Date.prototype.diffHours = function (this: Date, dist: Date): number {
  let res = -1;
  if (dist instanceof Date) {
    let diff = dist.getTime() - this.getTime();
    res = Math.round((diff) / 36e5);
  }
  return res;
};

Date.prototype.diffMinutes = function (this: Date, dist: Date): number {
  let res = -1;
  if (dist instanceof Date) {
    let diff = dist.getTime() - this.getTime();
    res = Math.floor((diff / 1000) / 60);
  }
  return res;
};

Date.prototype.toShortDateTime = function (this: Date): string {
  return ('0' + this.getDate()).substr(-2)
    + '.' + ('0' + (this.getMonth() + 1)).substr(-2)
    + ' ' + ('0' + this.getHours()).substr(-2)
    + ':' + ('0' + this.getMinutes()).substr(-2)
    + ':' + ('0' + this.getSeconds()).substr(-2);
};
