import { NgModule } from '@angular/core';

import { AdditionalLayer } from './history/additional-layer/additional.layer';
import { DeviceLayer } from './device-layer/device.layer';
import { DrawLayer } from './draw-layer/draw.layer';
import { HistoryLineLayer } from './history/lines-layer/lines.layer';
import { HistorPointsLayer } from './history/points-layer/points.layer';

@NgModule({
  declarations: [
    AdditionalLayer,
    DeviceLayer,
    DrawLayer,
    HistoryLineLayer,
    HistorPointsLayer
  ],
  exports: [
    AdditionalLayer,
    DeviceLayer,
    DrawLayer,
    HistoryLineLayer,
    HistorPointsLayer
  ]
})
export class MapLayersModule { }
