import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Settings } from 'core/models/settings';
import { SettingsResponse } from 'core/models/settings.response';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly settings: Map<string, string> = new Map<string, string>();

  constructor(
    private readonly http: HttpClient,
    @Inject('BASE_API_URL') private readonly baseUrl: string
  ) { this.baseUrl += '/settings' }

  public init(): Observable<any> {
    return this.http.get<SettingsResponse[]>(`${this.baseUrl}`)
      .pipe(
        map((settingsResponse: SettingsResponse[]) => {
          settingsResponse && settingsResponse.forEach((setting: SettingsResponse) => this.settings.set(setting.Name.toLowerFirst(), setting.Value));
        })
      );
  }

  public trySet(property: string, applyAction: Action): void {
    this.settings.has(property) && applyAction.call(this, this.settings.get(property));
  }

  public save(name: string, value: string): void {
    this.http.post(`${this.baseUrl}`, { name, value }).subscribe();
  }
}
