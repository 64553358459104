import FeatureAnimation from "./feature-animation";
import { olInherits } from "./ol-map-utils";

let FeatureZoom = function (options) {
  options = options || {};
  FeatureAnimation.call(this, options);
  this.set('zoomout', options.zoomOut);
}
olInherits(FeatureZoom, FeatureAnimation);
/** Animate
* @param {ol.featureAnimationEvent} e
*/
FeatureZoom.prototype.animate = function (e) {
  var fac = this.easing_(e.elapsed);
  if (fac) {
    if (this.get('zoomout'))
      fac = 1 / fac;
    var style = e.style;
    var i, imgs, sc = [];
    for (i = 0; i < style.length; i++) {
      imgs = style[i].getImage();
      if (imgs) {
        sc[i] = imgs.getScale();
        // ol >= v6
        if (e.type === 'postrender')
          imgs.setScale(sc[i] * fac / e.frameState.pixelRatio+.5); //TODO Анимация расходящейся окружности.
        else
          imgs.setScale(sc[i] * fac+.25);
      }
    }
    this.drawGeom_(e, e.geom);
    for (i = 0; i < style.length; i++) {
      imgs = style[i].getImage();
      if (imgs)
        imgs.setScale(sc[i]);
    }
  }
  /*
    var sc = this.easing_(e.elapsed);
    if (sc)
    {	e.context.save()
      console.log(e)
        var ratio = e.frameState.pixelRatio;
        var m = e.frameState.coordinateToPixelTransform;
        var dx = (1/(sc)-1)* ratio * (m[0]*e.coord[0] + m[1]*e.coord[1] +m[4]);
        var dy = (1/(sc)-1)*ratio * (m[2]*e.coord[0] + m[3]*e.coord[1] +m[5]);
        e.context.scale(sc,sc);
        e.context.translate(dx,dy);
        this.drawGeom_(e, e.geom);
      e.context.restore()
    }
  */
  return (e.time <= this.duration_);
}

let FeatureZoomOut = function (options) {
  options = options || {};
  options.zoomOut = true;
  FeatureAnimation.call(this, options);
}
olInherits(FeatureZoomOut, FeatureZoom);

export { FeatureZoom, FeatureZoomOut };
export default FeatureZoom;
