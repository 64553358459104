import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import Feature from "ol/Feature";
import { MapBrowserPointerEvent } from "ol";
import VectorLayer from "ol/layer/Vector";
import { isNull, notNull } from 'app/modules/shared/data/utils';

import { HistoryFeatureDevice } from 'modules/map/data/history';
import PopupOverlayBase from 'modules/map/data/popup-overlay-base';

import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'device-history-overlay',
  templateUrl: './device-history.overlay.html',
  styleUrls: ['./device-history.overlay.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceHistoryOverlay extends PopupOverlayBase {
  public get historyDevice() {
    return this._historyDevice;
  }
  public set historyDevice(historyDevice: HistoryFeatureDevice) {
    if (this._historyDevice === historyDevice) return;
    this._historyDevice = historyDevice;
    this.overlay.setPosition(historyDevice ? historyDevice.device.getLastCoordinate() : undefined);
    this.changeDetection.detectChanges();
  }

  private readonly LAYER_NAME = 'historyPointsLayer';
  private _historyDevice: HistoryFeatureDevice;

  constructor(
    private readonly changeDetection: ChangeDetectorRef,
    mapService: OlMapService
  ) { super(mapService); }

  ngAfterContentInit(): void {

    this.map.on('pointermove', (event: MapBrowserPointerEvent): void => {
      this.historyDevice = this.map.forEachFeatureAtPixel(event.pixel, (clusterFeature: Feature, layer: VectorLayer): HistoryFeatureDevice => {
        if (layer?.get('name') !== this.LAYER_NAME) return;
        const features: Array<Feature> = clusterFeature.get('features');
        return features?.length === 1 && features.first().get('device');
      });
    });
  }
}
