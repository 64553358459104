import { NgModule } from '@angular/core';

import { DeviceHistoryOverlay } from './device-history/device-history.overlay';
import { DevicePropsOverlay } from './device-props/device-props.overlay';
import { ContextMenuOverlay } from './context-menu/context-menu.overlay';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'modules/shared/shared.module';

@NgModule({
  declarations: [
    DeviceHistoryOverlay,
    DevicePropsOverlay,
    ContextMenuOverlay
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    DeviceHistoryOverlay,
    DevicePropsOverlay,
    ContextMenuOverlay
  ]
})
export class MapOverlaysModule { }
