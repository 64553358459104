import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { DeviceDetector } from 'app/core/data/device-detector';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard implements CanActivate {
  private readonly routeLink = { desktop: '/navigation', mobile: '/m_navigation' };

  constructor(
    private readonly deviceDetector: DeviceDetector,
    private readonly router: Router
  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isMobile = this.deviceDetector.isMobileOrTablet();

    if (state.url === this.routeLink.desktop && isMobile === false) {
      return true;
    }

    this.router.navigateByUrl(this.routeLink.mobile);
    return false;
  }
}
