import { ShortDevice } from "modules/history/models/short-device";
import { notNull } from "app/modules/shared/data/utils";

export class HistoryDevice {
  public id: number;
  public name: string;
  public color: string;
  public coordinates: Array<ShortDevice>;
}

export interface PeriodsHelper {
  name: string;
  getPeriod: Func;
}

export class DeviceHistory {
  isCheck: boolean = false;
  isVisible: boolean = true;

  constructor(
    public readonly id: string,
    public readonly name: string
  ) { }
}

export class MapPoint {

  constructor(
    public readonly latitude?: number,
    public readonly longitude?: number
  ) { }

  public getPoint(): Array<number> {
    return [this.longitude, this.latitude];
  }

  public get isValid() {
    return notNull(this.latitude) && isNaN(this.latitude) === false && notNull(this.longitude) && isNaN(this.longitude) === false;
  }
}

export class GeoRect {

  constructor(
    public readonly pointFirst: MapPoint = new MapPoint(),
    public readonly pointSecond: MapPoint = new MapPoint()
  ) { }

  public get isValid() {
    return this.pointFirst.isValid && this.pointSecond.isValid;
  }

  public getCoordinates(): Array<Array<number>> {
    return [this.pointFirst.getPoint(), this.pointSecond.getPoint()];
  }

  public getUri(): string {
    if (this.isValid === false) return '';
    return `&1stLat=${this.pointFirst.latitude}&1stLon=${this.pointFirst.longitude}&secLat=${this.pointSecond.latitude}&secLon=${this.pointSecond.longitude}`;
  }
}

export class HistoryPeriod {

  constructor(
    public readonly begin: Date = new Date(new Date().setHours(-24, 0, 0, 0)),
    public readonly end: Date = new Date(new Date().setHours(0, 0, 0, 0))
  ) { }

  public getUri(): string {
    return `&begin=${this.begin.toISOString()}&end=${this.end.toISOString()}`;
  }
}
