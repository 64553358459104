import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryComponentsModule } from '../components/history-components.module';
import { ComponentsSharedModule } from '../components-shared/components-shared.module';
import { MaterialModule } from 'modules/material/material.module';

import { HistoryListView } from './views/history-list/history-list.view';
import { HistoryQueryView } from './views/history-query/history-query.view';

@NgModule({
  declarations: [
    HistoryListView,
    HistoryQueryView
  ],
  imports: [
    CommonModule,
    HistoryComponentsModule,
    ComponentsSharedModule,
    MaterialModule
  ]
})
export class HistoryMobileModule { }
