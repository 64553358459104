import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import Logout from 'modules/shared/components/logout/logout';

import { AuthenticationService } from 'modules/authorization/services/authentication.service';

@Component({
  selector: 'mob-logout-button',
  templateUrl: './logout.button.html',
  styleUrls: ['./logout.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutButton extends Logout {

  constructor(
    authService: AuthenticationService,
    router: Router
  ) { super(authService, router); }
}
