import { ModuleWithProviders } from "@angular/compiler/src/core";
import { Route, RouterModule } from "@angular/router";

import { LoginComponent } from "./pages/login/login.page";

const routes: Array<Route> = [{
  path: '',
  component: LoginComponent,
  data: {
    defaultReuseStrategy: true, // Ignore our custom route strategy
    resetReuseStrategy: true // Logout redirect user to login and all data are destroyed
  }
}];

export const authorizationRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
