import { Component, Injector, ChangeDetectionStrategy } from '@angular/core';

import { DevicesBase } from 'modules/devices/components/devices';
import { SearchCriteria } from 'modules/devices/components-shared/filters/data/criteria';

import { FilterService } from 'modules/devices/components-shared/filters/services/filter.service';

@Component({
  selector: 'mob-devices',
  templateUrl: './devices-list.view.html',
  styleUrls: ['./devices-list.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesListView extends DevicesBase {

  constructor(
    private readonly filterService: FilterService,
    injector: Injector
  ) { super(injector); }

  public onSearchTextChanged(text: string): void {

// @ts-ignore
    if (typeof Android !== "undefined") {
// @ts-ignore
      if (text.toLowerCase() === 'clearcache')
// @ts-ignore
        Android.clearCache();
// @ts-ignore
      else if (text.toLowerCase().startsWith('uri:'))
// @ts-ignore
        Android.setUri(text.substring(4));
// @ts-ignore
      else if (text.toLowerCase().startsWith('toast:'))
// @ts-ignore
        Android.showToast(text.substring(6));
    }



    this.filterService.onCriteriaChanged(new SearchCriteria(text));
  }
}
