import { Component, Injector, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { HistoryDevice } from 'modules/history/models/history';
import { HistoryFeatureDevice } from 'modules/map/data/history';
import { HistoryEvent, MapEvent } from 'modules/shared/models/events';
import { ShortDevice } from 'modules/history/models/short-device';

import { MapCommService, HistoryCommService } from 'modules/shared/services/communication.service';
import { HistoryDataService } from 'modules/history/services/history-data.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class HistoryList {
  public history: HistoryDevice[];
  public selectedHistory: HistoryDevice;// = new HistoryDevice();

  protected readonly changeDetector: ChangeDetectorRef;
  protected readonly historyDataService: HistoryDataService;
  protected readonly historyService: HistoryCommService;
  protected readonly mapService: MapCommService;

  constructor(injector: Injector) {
    this.changeDetector = injector.get(ChangeDetectorRef);
    this.historyDataService = injector.get(HistoryDataService);
    this.historyService = injector.get(HistoryCommService);
    this.mapService = injector.get(MapCommService);
  }

  ngOnInit(): void {
    this.history = this.historyDataService.history;
    this.selectedHistory = this.history.first();

    this.historyService.on(HistoryEvent.OnMapSelected, (historyDevice: HistoryFeatureDevice): void => {
      if (this.selectedHistory.id !== historyDevice.id) {
        this.selectedHistory = this.history.find((device: HistoryDevice): boolean => device.id === historyDevice.id);
      }
      setTimeout(() => this.historyService.emit({ type: HistoryEvent.OnHistoryItemChoosen, value: historyDevice }), 300);
    });
  }

  ngOnDestroy(): void {
    this.historyDataService.clear();
    this.mapService.emit({ type: MapEvent.OnHistoryCleared });
  }
}
