import './app/core/data/array.extensions';
import './app/core/data/date.extensions';
import './app/modules/devices/data/device.extensions';
import './app/modules/devices/components-shared/filters/data/menu-criteria.extensions';
import './app/modules/shared/data/navigation.extensions';
import './app/modules/map/data/ol-feature.extensions';
import './app/modules/map/data/ol-map.extensions';
import './app/modules/map/data/ol-vector-layer.extensions';
import './app/modules/navigation/desktop/data/position.extensions';
import './app/modules/history/data/short-device.extensions';
import './app/modules/navigation/desktop/data/size.extensions';
import './app/core/data/string.extensions';
