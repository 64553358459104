export class Counter {

  constructor(
    public readonly devices: number = 0,
    public readonly filtered: number = -1
  ) { }

  //public equals(counter: any) {
  //  if ((counter instanceof Counter) === false) return false;
  //  for (let property in this) {
  //    if (Object.is(counter[property], this[property]) === false)
  //      return false;
  //  }

  //  return true;
  //}
}
