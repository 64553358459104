export class Position {
  public static get key() {
    return 'devs_ps';
  }

  constructor(
    public x?: number,
    public y?: number
  ) { }
}
