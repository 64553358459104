import { Component, Injector, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

import { DeviceSelector } from '../../data/device-selector';
import { HistoryDevice } from 'modules/history/models/history';
import { NavBarEvent } from 'modules/shared/models/events';

import { NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'device-selector-panel',
  templateUrl: './device-selector.panel.html',
  styleUrls: ['./device-selector.panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class DeviceSelectorPanel extends DeviceSelector {
  public get isShown() {
    return this._isShown;
  }
  public set isShown(value: boolean) {
    this._isShown = value;
    this.changeDetector.detectChanges();
  }

  @Output() public onHistorySelected: EventEmitter<HistoryDevice> = new EventEmitter();

  private _isShown: boolean;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    injector: Injector,
    private readonly navBarCommService: NavbarCommService
  ) { super(injector); }

  ngOnInit(): void {
    this.navBarCommService.on(NavBarEvent.OnDeviceSelectorToggled, (isShown: boolean) => this.isShown = isShown);
    super.ngOnInit();
  }

  public onDeviceSelect(device: HistoryDevice): void {
    this.onHistorySelected.emit(device);
    this.navBarCommService.emit({ type: NavBarEvent.OnDeviceSelectorToggled, value: false });
  }
}
