import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeMachineView } from './views/time-machine/time-machine.view';

@NgModule({
  declarations: [
    TimeMachineView
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TimeMachineView
  ]
})
export class TimeMachineDesktopModule { }
