import { OnInit, Injector, Component, EventEmitter } from "@angular/core";
import { LocationStrategy } from "@angular/common";
import { trigger, transition, style, animate } from "@angular/animations";

import Filters from "modules/devices/components-shared/filters/data/filters";
import { NavBarEvent } from "modules/shared/models/events";

import { NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'mob-filters-panel',
  templateUrl: './filters.panel.html',
  styleUrls: ['./filters.panel.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class FiltersPanel extends Filters implements OnInit {
  public get isShow() {
    return this._isShow;
  }
  public set isShow(isShow: boolean) {
    this._isShow = isShow;
    this.changeDetection.detectChanges();
  }
  private _isShow: boolean;

  constructor(
    injector: Injector,
    private readonly navBarCommService: NavbarCommService,
    private readonly location: LocationStrategy
  ) { super(injector); }

  ngOnInit(): void {
    super.ngOnInit();
    super.getSettings();
    super.staticMenuInit();
    this.preventBackButton();
    this.navBarCommService.on(NavBarEvent.OnFiltersToggled, (isShow: boolean) => this.isShow = isShow);
  }

  public onClose(): void {
    this.navBarCommService.emit({ type: NavBarEvent.OnFiltersToggled, value: false })
  }

  private preventBackButton(): void {
    history.pushState(null, null, window.location.href);
    this.location.onPopState((): void => {
      history.pushState(null, null, window.location.href);
      this.isShow = false;
    });
  }
}
