import { Injectable } from '@angular/core';

import { MapItem } from './mappers';
import { ObjectMapper } from './object-mapper';
import { activator } from './mapper-utils';
import { isNull } from 'app/modules/shared/data/utils';

@Injectable({
  providedIn: 'root'
})
export class Mapper {
  private readonly _mappings: Array<ObjectMapper> = [];

  private objectMapper: ObjectMapper;

  public createMap(source: Function, dist: Function): ObjectMapper {
    const mapper = new ObjectMapper(source, dist);
    this._mappings.push(mapper);
    return mapper;
  }

  public map<TSource, TDest>(sourceName: string, sourceArray: Array<TSource>): Array<TDest> {
    if (isNull(sourceName) || isNull(sourceArray)) return [];

    const mapper = this._mappings.find((objMapper: ObjectMapper) => objMapper.source.name === sourceName);
    if (isNull(mapper)) throw new Error(`Не задан маппер для типа ${sourceName}`);

    let mappers = mapper.mappers;

    if (mapper.derived.empty() === false) {
      mapper.derived.forEach((typeName: string) =>
        this._mappings
          .filter((objMapper: ObjectMapper) => objMapper.dest.name === typeName)
          .forEach((objMapper: ObjectMapper) => mappers = mappers.concat(objMapper.mappers)));
    }

    const destArray = new Array<any>();
    sourceArray.forEach((source: TSource) => {
      const destination = activator(mapper.dest as any);
      mappers.forEach((mapItem: MapItem) => mapItem.map.call(mapItem, source, destination));
      destArray.push(destination);
    });

    return destArray;
  }

  //public mapTo<TSource, TDest>(destName: string, sourceArray: Array<TSource>): TDest[] {
  //  if (isNull(destName) || isNull(sourceArray)) return;

  //  const mapping = this._mappings.find(map => map.typeName === destName);
  //  if (isNull(mapping)) throw new Error(`Не задан маппер для типа ${destName}`);

  //  const destArray = new Array<any>();
  //  sourceArray.forEach((source: TSource) => {
  //    const destination = activator(mapping.type);

  //    mapping.mappers.forEach((mapItem: MapItem) => mapItem.map.call(mapItem, source, destination));

  //    destArray.push(destination);
  //  });

  //  return destArray;
  //}

  //private objectMapper: ObjectMapper;

  //public createMap(type: Function): Mapper {
  //  this.objectMapper = new ObjectMapper();{ typeName: type.name, type, mappers: []};
  //  return this;
  //}

  //public add(item: MapItem): Mapper {
  //  this.objectMapper.mappers.push(item);
  //  return this;
  //}

  //public include(type: Function): Mapper {
  //  this.objectMapper.derived.push(type.name);
  //  return this;
  //}
}
