import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { AppEvent, MapEvent, NavBarEvent, RouteEvent } from 'modules/shared/models/events';

import { AppCommService, NavbarCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'nav-toggle-button',
  templateUrl: './toggle.button.html',
  styleUrls: ['./toggle.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleButton {
  public get isShown() {
    return this._isShown;
  }
  public set isShown(value: boolean) {
    this._isShown = value;
    this.changeDetection.detectChanges();
  }
  
  @Input() public icon: string;
  @Input() public navBarEvent: NavBarEvent;

  private _isShown: boolean = true;
  private _isToggled: boolean;

  constructor(
    private readonly appService: AppCommService,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly navBarCommService: NavbarCommService
  ) { }

  ngOnInit(): void {
    this.appService.on(AppEvent.OnRouteChanged, (event: RouteEvent) => this.isShown = event === RouteEvent.OnRoot);
    this.navBarCommService.on(this.navBarEvent, (value: boolean) => this._isToggled = value);
  }

  public toggle(): void {
    this.navBarCommService.emit({ type: this.navBarEvent, value: !this._isToggled });
  }
}
