import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";

import { AuthorizationModule } from './modules/authorization/authorization.module';
import { CoreModule } from './core/core.module';
import { DevicesModule } from './modules/devices/devices.module';
import { HistoryModule } from './modules/history/history.module';
import { MaterialModule } from './modules/material/material.module';
import { NavigationModule } from './modules/navigation/navigation.module';
import { SharedModule } from "modules/shared/shared.module";
import { TimeMachineModule } from './modules/time-machine/time-machine.module';

import { AppComponent } from "./app.component";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthorizationModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CoreModule,
    DevicesModule,
    HistoryModule,
    HttpClientModule,
    MaterialModule,
    NavigationModule,
    SharedModule,
    TimeMachineModule
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
