import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'modules/material/material.module';
import { SharedModule } from 'modules/shared/shared.module';

import { ButtonTextComponent } from './components/button-text/button-text.component';

import { DeviceSelectorMenu } from './desktop/device-selector-menu/device-selector.menu';
import { DeviceSelectorPanel } from './mobile/device-selector-panel/device-selector.panel';

@NgModule({
  declarations: [
    ButtonTextComponent,
    DeviceSelectorMenu,
    DeviceSelectorPanel
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ],
  exports: [
    DeviceSelectorMenu,
    DeviceSelectorPanel
  ]
})
export class DeviceSelectorModule { }
