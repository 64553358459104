import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'pipes/pipes.module';

import { DevicePropertiesComponent } from './device-props-component/device-props.component';
import { DevicePropsModal } from './device-props-modal/device-props.modal';
import { DevicePropsPanel } from './device-props-panel/device-props.panel';

@NgModule({
  declarations: [
    DevicePropertiesComponent,
    DevicePropsModal,
    DevicePropsPanel
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    DevicePropsModal,
    DevicePropsPanel
  ]
})
export class DevicePropertiesModule { }
