import { Component, AfterViewInit } from '@angular/core';

import { PanelBase } from '../data/panel-base';

@Component({
  selector: 'controls-panel',
  templateUrl: './controls.panel.html',
  styleUrls: ['./controls.panel.scss']
})
export class ControlsPanel extends PanelBase implements AfterViewInit {

  constructor() { super(); }

  ngAfterViewInit(): void {
    this.addControls();
  }
}
