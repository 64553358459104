import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import AccountResponse from '../../models/auth.response';

import { AlertService } from "core/services/alert.service";
import { AuthenticationService } from "app/modules/authorization/services/authentication.service";

import { DeviceDetector } from 'app/core/data/device-detector';

@Component({
  selector: 'login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;

  constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly zone: NgZone
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    (window as any).auth = this.onSubmitOutside.bind(this);
  }

  get f() { return this.loginForm.controls; }

  public onSubmit(): void {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    const form = this.loginForm.value;

    this.authenticationService.login({ login: form.username, password: form.password })
      .subscribe((response: AccountResponse): void => {
        this.alertService.clean();
        response.message && this.alertService.error(response.message);
        this.router.navigateByUrl('/navigation');
      },
        (errorResponse: any) => console.log(errorResponse)
      ).add(() => this.loading = false);
  }

  private onSubmitOutside(login: string, password: string): void {
    this.zone.run(() => {
      this.loginForm.controls['username'].setValue(login);
      this.loginForm.controls['password'].setValue(password);
      this.onSubmit();
    });
  }
}
