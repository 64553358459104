import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';

import { NavIntl } from './models/nav-intl';

@NgModule({
  declarations: [],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  exports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ru' },
    { provide: OwlDateTimeIntl, useClass: NavIntl }
  ]
})
export class OwlModule { }
