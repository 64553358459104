import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Device } from 'modules/devices/models/device';
import { DeviceResponse } from 'modules/devices/models/device.response';

import { Mapper } from 'modules/shared/mapper/mapper';
import { nameof } from 'app/modules/shared/data/utils';

@Injectable({
  providedIn: 'root'
})
export class LiveService {

  constructor(
    @Inject('BASE_API_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly mapper: Mapper
  ) { this.baseUrl += '/live' }

  public initialize(): Observable<Device[]> {
    return this.http.get<DeviceResponse[]>(`${this.baseUrl}/initialize`)
      .pipe(map((devices: DeviceResponse[]): Device[] => this.mapper.map(nameof(DeviceResponse), devices)));
  }
    
  public getLasts(): Observable<Device[]> {
    return this.http.get<DeviceResponse[]>(`${this.baseUrl}/last`)
      .pipe(map((devices: DeviceResponse[]) => this.mapper.map(nameof(DeviceResponse), devices)));
  }
}
