import { Component, ElementRef, ViewChild } from "@angular/core";

import { Map as OlMap, Overlay } from "ol";

import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({})
export default class PopupOverlayBase {
  protected overlay: Overlay;
  protected map: OlMap;

  @ViewChild('popup') public popup: ElementRef<HTMLElement>;

  constructor(
    private readonly mapService: OlMapService
  ) { }

  ngOnInit(): void {
    this.map = this.mapService.getMap();
  }

  ngAfterViewInit(): void {
    this.overlay = new Overlay({
      element: this.popup.nativeElement,
      offset: [10, -35]
    });
    this.map.addOverlay(this.overlay);
  }

  ngOnDestroy(): void {
    this.map.removeOverlay(this.overlay);
  }
}
