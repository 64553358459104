import { ModuleWithProviders } from "@angular/compiler/src/core";
import { Route, RouterModule } from "@angular/router";

import { NavigationPage } from "./pages/navigation/navigation.page";

import { SettingsResolver } from "resolvers/settings.resolver";

import { devicesMobileRoutes } from "modules/devices/mobile/devices-mobile.routes";
import { historyMobileRoutes } from "modules/history/mobile/history-mobile.routes";
import { timeMachineMobileRoutes } from "modules/time-machine/mobile/time-machine-mobile.routes";

const routes: Array<Route> = [{
  path: 'm_navigation',
  component: NavigationPage,
  resolve: { settings: SettingsResolver },
  children: [
    devicesMobileRoutes,
    historyMobileRoutes,
    timeMachineMobileRoutes
  ]
}];

export const navigationMobileRoutes: ModuleWithProviders = RouterModule.forChild(routes);
