import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ComponentsSharedModule } from '../components-shared/components-shared.module';
import { FormsModule } from '@angular/forms';
import { HistoryComponentsModule } from '../components/history-components.module';
import { PipesModule } from 'pipes/pipes.module';
import { SharedComponentsModule } from 'modules/shared/components/shared-components.module';

import { HistoryListView } from './views/history-list/history-list.view';
import { HistoryQueryView } from './views/history-query/history-query.view';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    HistoryListView,
    HistoryQueryView
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ComponentsSharedModule,
    FormsModule,
    HistoryComponentsModule,
    PipesModule,
    ScrollingModule,
    SharedComponentsModule
  ]
})
export class HistoryDesktopModule { }
