import { Route } from "@angular/router";

import { HistoryQueryView } from "./views/history-query/history-query.view";
import { HistoryListView } from "./views/history-list/history-list.view";

export const historyMobileRoutes: Route = {
  path: 'history',
  children: [
    { path: '', redirectTo: '/m_navigation/history/query', pathMatch: 'full' },
    { path: 'query', component: HistoryQueryView },
    { path: 'list', component: HistoryListView, data: { defaultReuseStrategy: true } }
  ],
  data: { defaultReuseStrategy: true }
};
