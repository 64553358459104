import { Injector, ChangeDetectorRef, Component, ChangeDetectionStrategy } from "@angular/core";

import { Activity } from "./activity";
import { CheckBoxItem, CloudView } from "../models/cloud";
import { Cloud } from "modules/devices/models/device";
import { CloudEvent } from "modules/shared/models/events";
import { Menu, MultipleMenu, SingleMenu } from "modules/devices/components-shared/filters/models/menu";
import { MenuCriteria } from "./criteria";
import { SelectedCloud } from "modules/devices/components-shared/filters/models/selected-menu";
import { activityGroup } from "./activities";
import { isNull } from "modules/shared/data/utils";

import { CloudCommService } from "modules/shared/services/communication.service";
import { FilterService } from "../services/filter.service";
import { SettingsService } from "core/services/settings.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class Filters {
  public activities: Array<string> = activityGroup.activities.map(activity => activity.name);
  public criteria: MenuCriteria = new MenuCriteria();
  public menus: Array<MultipleMenu> = [];

  protected readonly changeDetection: ChangeDetectorRef;
  private readonly cloudService: CloudCommService;
  private readonly filterService: FilterService;
  private readonly settingsService: SettingsService;

  private readonly FILTERS_KEY = 'filters';

  constructor(
    injector: Injector
  ) {
    this.changeDetection = injector.get(ChangeDetectorRef);
    this.cloudService = injector.get(CloudCommService);
    this.filterService = injector.get(FilterService);
    this.settingsService = injector.get(SettingsService);
  }

  public ngOnInit(): void {

    this.cloudService.on(CloudEvent.OnCloudsReceived, (clouds: Cloud[]): void => {
      clouds.forEach((cloud: Cloud): void => {
        let cloudMenu = this.menus.find((cloudMenu: MultipleMenu): boolean => cloudMenu.cloudName === cloud.name);

        if (isNull(cloudMenu)) {
          cloudMenu = new MultipleMenu(cloud.name);
          this.menus.unshift(cloudMenu);
        }
        if (cloudMenu.subMenus.every((subMenu: CheckBoxItem): boolean => subMenu.text !== cloud.value)) {
          const isCheck = this.criteria.clouds.find((criteriaCloud: SelectedCloud): boolean => criteriaCloud.name === cloud.name)?.has(cloud.value);
          cloudMenu.subMenus.push({ text: cloud.value, isCheck: isCheck || false });
        }
      });
    });

    this.cloudService.on(CloudEvent.OnCloudUnselected, (cloud: CloudView): void => {
      const subMenu = this.menus.find((menu: MultipleMenu) => menu.cloudName === cloud.name)?.subMenus.find((submenu: CheckBoxItem) => submenu.text === cloud.item);
      if (subMenu) {
        subMenu.isCheck = false;
      }
      this.onChange();
    });

    this.criteria.subscribe(() => this.filterService.onCriteriaChanged(this.criteria));
  }

  public getSettings(): void {
    this.settingsService.trySet(this.FILTERS_KEY, (value: any) => this.criteria.deserialize(value));
    if (this.criteria.empty()) return;
    this.filterService.onCriteriaChanged(this.criteria);
    this.cloudService.emit({ type: CloudEvent.OnCloudsSelected, value: this.criteria.clouds.map((cloud: SelectedCloud) => cloud.toView()).flatten() });
    this.changeDetection.detectChanges();
  }

  public staticMenuInit(): void {
    const cloudMenu = new SingleMenu(
      activityGroup.name,
      activityGroup.activities.map((activity: Activity) => ({
        text: activity.name,
        isCheck: this.criteria.clouds.find(criteriaCloud => criteriaCloud.name === activityGroup.name)?.has(activity.name)
      }))
    );
    this.menus.push(cloudMenu);
  }

  public onClose(cloud: SelectedCloud, item: string): void {
    const subMenu = this.menus.find((menu: MultipleMenu) => menu.cloudName === cloud.name)?.subMenus.find((submenu: CheckBoxItem) => submenu.text === item);
    if (subMenu) {
      subMenu.isCheck = false;
    }

    this.onChange();
  }

  public onChange(): void {
    this.criteria.clouds = this.menus
      .filter((menu: Menu) => menu.subMenus.some((subMenu: CheckBoxItem) => subMenu.isCheck))
      .map((menu: Menu) => menu.toSelected());

    this.cloudService.emit({ type: CloudEvent.OnCloudsSelected, value: this.criteria.clouds.map((cloud: SelectedCloud) => cloud.toView()).flatten() });
    this.settingsService.save(this.FILTERS_KEY, JSON.stringify(this.criteria.clouds));
  }
}
