import { Navigation, State } from "modules/shared/models/navigation";

export class ShortDevice {
  public lastNav: Navigation;
  public navState: number;
  public channel: State;
  public stateNav: State;
  public equipState: State;
  public crewStatement: string;
  public mode: State;
  public power: State;
  public relays: string;
  public alarm: string;
  public statement: string;
  public wire: string;
  public guard: string;
  public isParked: boolean;
  public receiveTime: Date;
  public context: string;
  public mark: string;
}
