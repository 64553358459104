import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ShortDevice } from 'modules/history/models/short-device';
import { calcSpeed } from 'app/modules/shared/data/utils';

@Component({
  selector: 'parked',
  templateUrl: './parked.component.html',
  styleUrls: ['./parked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParkedComponent {
  @Input() public device: ShortDevice;

  constructor() { }

  public get speed() {
    return this.device.isValid() ? this.device.lastNav.speed : 0;
  }

  public get calcColor() {
    return this.device.isValid() ? calcSpeed(this.speed) : null;
  }
}
