import { NgModule } from '@angular/core';

import { authorizationRoutes } from '../authorization/authorization.routes';
import { navigationDesktopRoutes } from '../navigation/desktop/navigation-desktop.routes';
import { navigationMobileRoutes } from '../navigation/mobile/navigation-mobile.routes';

@NgModule({
  imports: [
    authorizationRoutes,
    navigationDesktopRoutes,
    navigationMobileRoutes
  ]
})
export class AppRoutingModule { }
