import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from 'modules/shared/shared.module';

import { DeviceSelectorModule } from './device-selector/device-selector.module';
import { SelectedHistoryPanel } from './selected-history/selected-history.panel';

@NgModule({
  declarations: [
    SelectedHistoryPanel
  ],
  imports: [
    CommonModule,
    DeviceSelectorModule,
    ScrollingModule,
    SharedModule
  ],
  exports: [
    DeviceSelectorModule,
    SelectedHistoryPanel
  ]
})
export class ComponentsSharedModule { }
