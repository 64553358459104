import { Component, HostListener, Inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ScreenBase } from 'modules/navigation/mobile/data/full-screen';
import { screenModes } from 'modules/navigation/mobile/data/screen-modes';

@Component({
  selector: 'full-screen-button',
  templateUrl: './full-screen.button.html',
  styleUrls: ['./full-screen.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullScreenButton implements OnInit {
  public isEnabled: boolean;

  private _screenManager: ScreenBase;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  onScreenChange(): void {
    this.isEnabled = this.document.fullscreen;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  public ngOnInit(): void {
    this.isEnabled = this.document.fullscreen;
    const documentElement = this.document.documentElement;
    for (let mode of screenModes) {
      if (documentElement[mode.enableFunc] && this.document[mode.disableFunc]) {
        this._screenManager = new mode.type(this.document);
        break;
      }
    }
  }

  public toggle(): void {
    this._screenManager[this.isEnabled ? 'disable' : 'enable'].call(this._screenManager);
  }
}
