import { updateByProps } from "app/modules/shared/data/utils";

Array.prototype.groupBy = function <T, K>(this: Array<T>, keyGetter): Map<T, Array<K>> {
  const map = new Map();
  this.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

Array.prototype.update = function <T extends object>(this: Array<T>, target: T, byProp: string) {
  if (!byProp || !target) throw new Error('Не все аргументы определены');
  if (!target.hasOwnProperty(byProp)) throw new Error(`Объект не содержит свойства ${byProp}`);
  const index = this.findIndex((item: T) => item[byProp] === target[byProp]);

  if (index === -1) return;

  const source = { ...this[index] };
  updateByProps(target, source);
  Object.setPrototypeOf(source, target);
  this[index] = source;
};

Array.prototype.insertUnique = function <T>(this: Array<T>, item: T) {
  return (item && !this.has(item)) ? this.push(item) : -1;
};

Array.prototype.insertSort = function <T>(this: Array<T>, item: T) {
  if (!item || this.has(item)) return;

  let low = 0,
    high = this.length;

  while (low < high) {
    let middle = (low + high) >>> 1;
    if (this[middle] < item) {
      low = middle + 1;
    } else {
      high = middle;
    }
  }
  this.splice(low, 0, item);
};

Array.prototype.empty = function <T>(this: Array<T>) {
  return this.length === 0;
};

Array.prototype.first = function <T>(this: Array<T>) {
  return this.length > 0 ? this[0] : null;
};

Array.prototype.flatten = function <T>(this: Array<T>) {
  return this.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? toFlatten.flatten() : toFlatten);
  }, []);
};

Array.prototype.has = function <T>(this: Array<T>, element: T) {
  return this.indexOf(element) !== -1;
};

Array.prototype.last = function <T>(this: Array<T>) {
  return this.length > 0 ? this[this.length - 1] : null;
};

Array.prototype.remove = function <T>(this: Array<T>, element: T) {
  const index = this.indexOf(element);
  if (index !== -1) {
    this.splice(index, 1);
    return this.length;
  }
  return index;
};

Array.prototype.removeAll = function <T>(this: Array<T>) {
  while (this.length) {
    this.pop();
  }
};
