import { Component, Injector } from '@angular/core';

import HistoryList from 'modules/history/components-shared/history-list/history-list';

@Component({
  templateUrl: './history-list.view.html',
  styleUrls: ['./history-list.view.scss']
})
export class HistoryListView extends HistoryList {

  constructor(injector: Injector) { super(injector); }
}
