import { Pipe, PipeTransform, Inject } from '@angular/core';

import { Counter } from 'modules/shared/data/counter';
import { Device } from 'modules/devices/models/device';
import { Filter } from 'modules/devices/components-shared/filters/data/filter.class';

import { DeviceCommService } from 'modules/shared/services/communication.service';
import { DeviceEvent } from 'modules/shared/models/events';

@Pipe({
  name: 'deviceFilter',
  pure: false
})
export class DeviceFilterPipe implements PipeTransform {

  constructor(
    private readonly deviceService: DeviceCommService
  ) { }

  transform(devices: Array<Device>, filter: Filter): Array<Device> {
    const devicesCount = devices.length;
    let filtered = -1;

    if (filter.empty() === false) {
      devices = devices.filter((device: Device): boolean => filter.inCriterias(device));
      filtered = devices.length;
    }

    this.deviceService.emit({ type: DeviceEvent.OnItemsFiltered, value: new Counter(devicesCount, filtered) });
    return devices;
  }
}
