import { Component, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';

import { isNull } from 'modules/shared/data/utils';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None //Всплывайка календаря
})
export class TimePickerComponent {
  @Input() public time: Date;

  @Output() public OnTimeChanged: EventEmitter<Date> = new EventEmitter();

  constructor() { }

  public onTimeChanged(event: string): void {
    if (isNull(event)) return;

    const formattedDate = event.replace(/(\d+).(\d+).(\d+),/, '$3/$2/$1');
    this.time = new Date(formattedDate);
    this.OnTimeChanged.emit(this.time);
  }
}
