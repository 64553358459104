import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryComponentsModule } from 'modules/history/components/history-components.module';
import { MapModule } from 'modules/map/map.module';
import { NavigationButtonsModule } from './buttons/navigation-buttons.module';
import { SharedModule } from 'modules/shared/shared.module';
import { SharedComponentsModule } from 'modules/shared/components/shared-components.module';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StatusBarComponent } from './components/status-bar/status-bar.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { NavigationPage } from './pages/navigation/navigation.page';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    StatusBarComponent,
    NavigationPage
  ],
  imports: [
    CommonModule,
    HistoryComponentsModule,
    MapModule,
    NavigationButtonsModule,
    SharedModule,
    SharedComponentsModule
  ]
})
export class NavigationMobileModule { }
