export class Size {
  public static get key() {
    return 'devs_sz';
  }
  public get width() {
    return this._width;
  }
  public set width(width: number) {
    this._width = Math.max(width, this._min_width);
  }

  public get height() {
    return this._height;
  }
  public set height(height: number) {
    this._height = Math.max(height, this._min_height);
  }

  public set min_width(width: number) {
    this._min_width = width;
  }

  public set min_height(height: number) {
    this._min_height = height;
  }

  private _min_width: number = 200;
  private _min_height: number = 100;

  private _width: number = this._min_width;
  private _height: number = this._min_height;

  constructor(
    min_height?: number,
    min_width?: number,
    height?: number,
    width?: number
  ) {
    this._min_height = min_height;
    this._min_width = min_width;
    this.height = height;
    this.width = width;
  }
}
