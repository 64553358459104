import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { DeviceEvent } from 'modules/shared/models/events';

import { DeviceCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'property-button',
  templateUrl: './property.button.html',
  styleUrls: ['./property.button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyButton {

  constructor(
    private readonly deviceService: DeviceCommService
  ) { }

  public onPropClick(): void {
    this.deviceService.emit({ type: DeviceEvent.OnDeviceDetail });
  }
}
