import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AppEvent, RouteEvent } from 'modules/shared/models/events';
import { routeEvents } from '../data/routes';

import { AppCommService } from 'modules/shared/services/communication.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(
    private readonly appCommService: AppCommService,
    private readonly router: Router
  ) { }

  public init(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) =>
        routeEvents.forEach(route => {
          if (/^(\/\w+)$/.test(event.url)) {
            this.appCommService.emit({ type: AppEvent.OnRouteChanged, value: RouteEvent.OnRoot });
          }

          const routePath: string = event.url.split('/')[2];

          for (let index = 0; index < routeEvents.length; index++) {
            if (routePath !== route.path) continue;
            this.appCommService.emit({ type: AppEvent.OnRouteChanged, value: route.event })
            break;
          }
        }));
  }
}
