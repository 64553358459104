import { Component, OnInit } from '@angular/core';

import Feature from "ol/Feature";
import { Map as OlMap } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from "ol/layer/Vector";
import { Vector as VectorSource } from "ol/source";

import { HistoryDevice } from 'modules/history/models/history';
import { HistoryEvent } from 'modules/shared/models/events';
import { ShortDevice } from 'modules/history/models/short-device';
import { createSvgStyle } from 'modules/map/data/ol-map-utils';

import { HistoryCommService } from 'modules/shared/services/communication.service';
import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'history-additional-layer'
})
export class AdditionalLayer implements OnInit {
  private _pointLayer: VectorLayer;
  private _pointSource: VectorSource = new VectorSource();
  private _map: OlMap;

  private _timeout: NodeJS.Timeout;

  constructor(
    private readonly historyService: HistoryCommService,
    private readonly olMapService: OlMapService
  ) { }

  ngOnInit(): void {
    this._map = this.olMapService.getMap();
    this._pointLayer = new VectorLayer({ name: 'historyAdditionalLayer', source: this._pointSource });
    this._map.addLayer(this._pointLayer);

    this.historyService.on(HistoryEvent.OnItemShownOnMap, (device: ShortDevice): void => {
      const feature = new Feature(new Point(device.getLastCoordinate()));
      feature.setStyle(createSvgStyle(device.mark));
      this._pointSource.addFeature(feature);
      clearTimeout(this._timeout);
      this._timeout = setTimeout((): void => this._pointSource.clear(), 5000);
    });
  }
}
