import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Feature, Map, Overlay, MapBrowserPointerEvent } from "ol";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from 'ol/proj';

import { Device } from 'modules/devices/models/device';
import { MapEvent } from 'modules/shared/models/events';
import PopupOverlayBase from 'modules/map/data/popup-overlay-base';
import { notNull, isNull } from 'app/modules/shared/data/utils';

import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'device-props-overlay',
  templateUrl: './device-props.overlay.html',
  styleUrls: ['./device-props.overlay.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePropsOverlay extends PopupOverlayBase {
  public get device() {
    return this._device;
  }
  public set device(device: Device) {
    this._device = device;
  }

  private readonly LAYER_NAME = 'devicesLayer';
  private _device: Device;

  constructor(
    private readonly changeDetection: ChangeDetectorRef,
    mapService: OlMapService
  ) { super(mapService); }

  ngAfterContentInit(): void {
    this.map.on('pointermove', (event: MapBrowserPointerEvent): void => {
      const device = this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer: VectorLayer): Device => {
        if (layer?.get('name') !== this.LAYER_NAME) return;
        return feature.get('device');
      });
      if (device === this.device) return;
      this.device = device;
      this.changeDetection.detectChanges();
      this.overlay.setPosition(device ? device.getValidCoordinate() : undefined);
      if (isNull(device)) return;

      const mousePosition: Array<number> = event.pixel;
      const elemWidth: number = this.popup.nativeElement.offsetWidth;
      const elemHeight: number = this.popup.nativeElement.offsetHeight;
      const x: number = window.innerWidth < mousePosition.first() + elemWidth ? -(elemWidth + 10) : 5;
      const y: number = window.innerHeight > mousePosition.last() + elemHeight ? 10 : -(elemHeight + 10);
      this.overlay.setOffset([x, y]);
    });
  }
}
