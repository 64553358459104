export function rgbToHex(red: number, green: number, blue: number) {
  return '#' + [red, green, blue].map(x => Math.trunc(Math.min(x, 255)).toString(16).padStart(2, '0')).join('');
}

/**
 * Рассчёт цвета скорости объекта.
 * @param speed Значение скорости
 * @returns Цвет в HEX.
 * //TODO Перенести в parked.component
 */
export function calcSpeed(speed: number): string {
  return rgbToHex(
    Math.max(32, Math.log2(Math.max(90, speed) - 90) * 32),
    Math.max(32, 240 - Math.pow(speed - 60, 2) / 8),
    Math.max(32, 240 - Math.pow(speed, 2) / 8)
  );
}

/**
 * Конвертирование целочисленного значения в HEX
 * @param value Цвет в decimal
 * @returns Значение в HEX
 */
export function decimalToHex(value: number) {
  const rgb = ((value & 0xFF0000) >> 16) | (value & 0x00FF00) | ((value & 0x0000FF) << 16);
  return ("000000" + rgb.toString(16).toUpperCase()).substr(-6);
}

/**
 * Получение наименования объекта из его экземпляра.
 * @param object Целевой объект.
 * @returns Наимнование целевого объекта.
 */
export function nameof(object: any) {
  return object.hasOwnProperty('name') ? object.name : object.constructor.name;
}

/**
 * Обновление объекта по свойствам.
 * В реализации, обновляется класс Device.
 * Обновление производится тогда, когда целевое свойство не равно новому по значению и отлично от undefined.
 * @param target Целовой объект.
 * @param source Новый объект.
 */
export function updateByProps(target: any, source: any) {
  if (typeof target === "undefined" || typeof source === "undefined") return;
  Object.keys(target).forEach((property: string) => {
    if (source.hasOwnProperty(property) && (property == 'code' || property == 'description' || target[property])) {
      const prop = target[property];
      if (typeof prop !== 'object' || prop instanceof Date) {
        source[property] = prop;
      } else {
        updateByProps(prop, source[property]);
      }
    }
  });
}

/**
 * Проверка значения на null & undefined.
 * @param value Целевой объект.
 * @returns Результат проверки.
 */
export function notNull(value: any): boolean {
  return value !== undefined && value !== null;
}

/**
 * Проверка что объект null | undefined
 * @param value Целевой объект.
 * @returns Результат проверки.
 */
export function isNull(value: any): boolean {
  return value === undefined || value === null;
}

/**
 * Преобразование в булевое значение.
 * @param value Предполагаемое булевое значение.
 * @returns Булевое значение.
 */
export function parseBoolean(value: any): boolean {
  return isNaN(value) ? false : Boolean(Number(value));
}
