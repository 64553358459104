import { Injector, ChangeDetectorRef, Component, ChangeDetectionStrategy } from "@angular/core";
import { Subscription, interval, timer } from "rxjs";

import { CloudSetting } from "../components-shared/filters/models/cloud";
import { Device, Changed, Cloud } from "modules/devices/models/device";
import { DeviceEvent, CloudEvent } from "modules/shared/models/events";
import { Filter } from "../components-shared/filters/data/filter.class";

import { AlertService } from "core/services/alert.service";
import { DeviceCommService, CloudCommService } from "modules/shared/services/communication.service";
import { LiveService } from "core/services/live.service";
import { SettingsService } from "core/services/settings.service";
import { isNull, notNull } from "app/modules/shared/data/utils";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesBase {
  public devices: Array<Device> = [];
  public filter: Filter = new Filter();

  protected readonly changeDetection: ChangeDetectorRef;
  protected readonly cloudService: CloudCommService;
  protected readonly deviceService: DeviceCommService;
  protected readonly liveService: LiveService;
  protected readonly settingsService: SettingsService

  private cloudSettings: Array<CloudSetting> = [new CloudSetting('leftSideCloud'), new CloudSetting('rightSideCloud')];
  private _getLiveDevicesSubscribtion$: Subscription;
  private _filterDevicesSubscribtion$: Subscription;

  constructor(
    private readonly injector: Injector
  ) {
    this.changeDetection = injector.get(ChangeDetectorRef);
    this.cloudService = injector.get(CloudCommService);
    this.deviceService = injector.get(DeviceCommService);
    this.liveService = injector.get(LiveService);
    this.settingsService = injector.get(SettingsService);
  }

  ngOnInit(): void {
    this.liveService.initialize().subscribe((devices: Device[]) => {
      this.onGotDevices(devices);

      this._getLiveDevicesSubscribtion$ = interval(7000)
        .subscribe(() => this.liveService.getLasts().subscribe((devices: Device[]): void => this.onGotDevices(devices)));
    }).add(() => timer(3000).subscribe(() => this.deviceService.emit({ type: DeviceEvent.OnDevicesLoaded, value: null })));

    this.cloudSettings.forEach((cloudSetting: CloudSetting) => this.settingsService.trySet(cloudSetting.key, (value: any) => cloudSetting.value = value));

    this.deviceService.on(DeviceEvent.OnFilterChanged, (filter: Filter) => {
      this.filter = filter;
      this.changeDetection.detectChanges();
      notNull(this._filterDevicesSubscribtion$) && (this._filterDevicesSubscribtion$ = interval(60000).subscribe(() => this.changeDetection.detectChanges()));
    });
  }

  ngOnDestroy(): void {
    this._filterDevicesSubscribtion$?.unsubscribe();
    this._getLiveDevicesSubscribtion$?.unsubscribe();
  }

  public onSingleInteraction(device: Device): void {
    this.deviceService.emit({ type: DeviceEvent.OnDeviceChoosen, value: device });
  }

  public onDoubleInteraction(device: Device): void {
    this.deviceService.emit({ type: DeviceEvent.OnDeviceDetail, value: device });
  }

  private onGotDevices(devices: Device[]): void {
    if (isNull(devices) || devices.empty()) return;
    console.log(devices);
    devices.forEach((device: Device): void => {
      this.addOrUpdate(device);
      device.clouds && this.cloudService.emit({ type: CloudEvent.OnCloudsReceived, value: device.clouds });
      this.deviceService.emit({ type: DeviceEvent.OnDeviceReceived, value: device });
    });
    this.devices = [... this.devices];
    this.changeDetection.detectChanges();
  }

  private addOrUpdate(device: Device): void {
    const sourceDevice = this.devices.find((item: Device) => item.id === device.id);
    if (sourceDevice) {
      sourceDevice.changed = sourceDevice.lastNav.time?.getTime() !== device.lastNav.time?.getTime() ? Changed.CHANGED_TIME : Changed.CHANGED_ANOTHER;
      this.devices.update(device, 'id');
    } else {
      this.devices.push(device);
    }

    this.cloudSettings.forEach((cloudSetting: CloudSetting) => {
      const cloud = device.clouds?.find((cloud: Cloud) => cloud.name === cloudSetting.value);
      cloud && device.selectedClouds.push({ name: cloud.value, title: `${cloudSetting.value}: ${cloud.value}` });
    });
  }
}
