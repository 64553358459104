import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { SettingsService } from "core/services/settings.service";

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly settingsService: SettingsService
  ) { }

  public applyStyles(): void {
    this.settingsService.trySet('rootFontSize', (value: any) => this.document.documentElement.style.fontSize = value);
  }

  public resetStyles(): void {
    this.document.documentElement.style.fontSize = '';
  }
}
