import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppInitService } from 'core/services/app.init.service';
import { DeviceDataService } from 'modules/shared/services/device-data.service';
import { RoutesService } from './services/routes.service';

@NgModule({
  providers: [
    AppInitService, {
      provide: APP_INITIALIZER,
      useFactory: (appInit: AppInitService) => () => appInit.init(),
      deps: [AppInitService],
      multi: true
    },
    DeviceDataService, {
      provide: APP_INITIALIZER,
      useFactory: (deviceDataServce: DeviceDataService) => () => deviceDataServce.init(),
      deps: [DeviceDataService],
      multi: true
    },
    RoutesService, {
      provide: APP_INITIALIZER,
      useFactory: (routes: RoutesService) => () => routes.init(),
      deps: [RoutesService],
      multi: true
    },
  ]
})
export class InitializeModule { }
