import { Component, Input, OnInit } from '@angular/core';

import { Map, View } from 'ol';
import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'zoom-control',
  templateUrl: './zoom.control.html',
  styleUrls: ['./zoom.control.scss']
})
export class ZoomControl implements OnInit {
  @Input() public classNamePlus: string;
  @Input() public classNameMinus: string;

  private map: Map;

  constructor(
    private readonly mapService: OlMapService
  ) { }

  ngOnInit(): void {
    this.map = this.mapService.getMap();
  }

  public onZoomInClick(): void {
    this.map.zoomIn();
  }

  public onZoomOutClick(): void {
    this.map.zoomOut();
  }
}
