import { State } from "modules/shared/models/navigation";

export const stringConverters = {
  number: (value: number): string => value.toString(),
  string: (value: string): string => value,
  state: (value: State): string => value.description
};

export const searchPropertyNames: Array<string> = [
  "id",
  "accNumber",
  "callSign",
  "phone",
  "hardware",
  "firmware",
  "regNumber",
  "name",
  "subdivision",
  "service",
  "alarm",
  "statement",
  "wire",
  "navState",
  "channel",
  "power",
  "mode"
];
