import { Filter } from "./filter.class";
import { Notify } from "./notify.class";
import { Device } from "modules/devices/models/device";
import { SelectedCloud } from "../models/selected-menu";
import { stringConverters, searchPropertyNames } from "./convertors";
import { isNull } from "modules/shared/data/utils";

export abstract class Criteria {
  public abstract addCriteria(filter: Filter): void;
}

export class MenuCriteria extends Notify implements Criteria {
  private timer: NodeJS.Timer;

  public get clouds() { return this._clouds; }
  public set clouds(clouds: Array<SelectedCloud>) {
    this._clouds = clouds;
    this.raiseChanged();
  }

  private _clouds: Array<SelectedCloud> = [];

  public subscribe(action: Action): void {
    if (isNull(action)) return;
    super.subscribe(action);
  }

  public addCriteria(filter: Filter): void {
    return this.clouds.empty() === false && this.clouds.forEach((cloud: SelectedCloud) => filter.add(cloud.toFilter()));
  }

  public empty(): boolean {
    return this.clouds.empty();
  }

  private raiseChanged(): void {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.onChange?.call(this), 2000);
  }
}

export class SearchCriteria implements Criteria {
  public searchText: string;

  constructor(searchText: string) {
    this.searchText = searchText;
  }

  public addCriteria(filter: Filter): void {
    if (!this.searchText || this.searchText.length < 3) return;

    filter.add((device: Device) => {
      return Object.keys(device)
        .filter((property: string): boolean => searchPropertyNames.includes(property))
        .some((property: string): boolean => {
          const value = device[property];
          const type = typeof value;
          if (stringConverters.hasOwnProperty(type)) {
            return stringConverters[type].call(this, value).toLowerCase().includes(this.searchText.toLowerCase());
          }
        })
    });
  }
}

