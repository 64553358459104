import { Component, Injector } from '@angular/core';

import HistoryQuery from 'modules/history/components-shared/history-query/history-query';

@Component({
  templateUrl: './history-query.view.html',
  styleUrls: ['./history-query.view.scss']
})
export class HistoryQueryView extends HistoryQuery {

  constructor(injector: Injector) {
    super(injector);
    this.limit = 5000;
  }
}
