import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { SettingsService } from "core/services/settings.service";

@Injectable({
  providedIn: 'root'
})

export class SettingsResolver implements Resolve<any> {

  constructor(
    private readonly settingService: SettingsService
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.settingService.init();
  }
}
