export interface NavLink {
  name: string;
  route: string;
  title: string;
  icon: string;
}

export const navLinks: Array<NavLink> = [
  { name: 'Устройства', route: './', title: 'Список объектов', icon: 'navigation' },
  { name: 'История', route: 'history', title: 'История перемещения объектов', icon: 'map' },
  { name: 'Машина времени', route: 'time-machine', title: 'Машина Времени', icon: 'access_time' }
];
