import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '../models/auth';
import AccountResponse from '../models/auth.response';
import { CloudEvent, DeviceEvent, MapEvent } from 'modules/shared/models/events';

import { DeviceCommService, MapCommService, CloudCommService } from 'modules/shared/services/communication.service';
import { LocalStorageService } from 'core/services/localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly STORAGE_KEY: string = 'user-auth';

  constructor(
    @Inject('BASE_API_URL') private readonly baseUrl: string,
    private readonly cloudService: CloudCommService,
    private readonly deviceService: DeviceCommService,
    private readonly localStorageService: LocalStorageService,
    private readonly mapService: MapCommService,
    private readonly http: HttpClient
  ) { }

  public login(account: Account): Observable<AccountResponse> {
    return this.http.post<AccountResponse>(`${this.baseUrl}/authenticate/login`, account)
      .pipe(map((response: AccountResponse): AccountResponse => {
        this.localStorageService.set(this.STORAGE_KEY, response.accessToken);
        return response;
      }));
  }

  public logout(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/account/logout`, null);
  }

  public clear(): void {
    this.localStorageService.clear();
    this.cloudService.dispose();
    this.deviceService.dispose();
    this.mapService.dispose();
  }

  public isLoggedIn(): boolean {
    return this.localStorageService.get(this.STORAGE_KEY) !== null;
  }

  public getToken(): string {
    return this.localStorageService.get(this.STORAGE_KEY);
  }
}
