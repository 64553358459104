import { Device, Cloud } from "modules/devices/models/device";
import { CloudView } from "./cloud";
import { activityGroup } from "../data/activities";

export abstract class SelectedCloud {

  constructor(
    public readonly name: string
  ) { }

  public abstract has(cloud: string): boolean;
  public abstract toFilter(): Func;
  public abstract toView(): CloudView | CloudView[];
}

export class SelectedSingle extends SelectedCloud {

  constructor(
    public readonly name: string,
    public readonly selected: string
  ) {
    super(name);
  }

  public has(cloud: string): boolean {
    return this.selected === cloud;
  }

  public toFilter(): Func {
    return (device: Device) => activityGroup.name === this.name
      && activityGroup.activities.find(activity => activity.name === this.selected)?.isValid(device);
  }

  public toView(): CloudView | CloudView[] {
    return { name: this.name, item: this.selected };
  }
}

export class SelectedMultiple extends SelectedCloud {

  constructor(
    public readonly name: string,
    public readonly items: Array<string> = []
  ) {
    super(name);
  }

  public has(cloud: string): boolean {
    return this.items.has(cloud);
  }

  public toFilter(): Func {
    return (device: Device) => this.items.has(device.clouds?.find((deviceCloud: Cloud) => deviceCloud.name === this.name)?.value)
  }

  public toView(): CloudView | CloudView[] {
    return this.items.map((item: string) => ({ name: this.name, item }));
  }
}
