import { Component, ChangeDetectionStrategy, Injector } from "@angular/core";

import { HistoryDevice } from 'modules/history/models/history';

import { HistoryDataService } from 'modules/history/services/history-data.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSelector {
  public historyDevices: HistoryDevice[];

  protected readonly historyDataService: HistoryDataService;

  constructor(
    injector: Injector
  ) {
    this.historyDataService = injector.get(HistoryDataService);
  }

  public ngOnInit(): void {
    this.historyDevices = this.historyDataService.history;
  }
}
