import { Injectable } from '@angular/core';

import { Map as OlMap } from 'ol';
import OlView from 'ol/View';
import OSM from "ol/source/OSM";
import TileLayer from 'ol/layer/Tile';
import VectorLayer from "ol/layer/Vector";

import { defaults as defaultControls } from 'ol/control';
import { fromLonLat } from 'ol/proj';

//import { NavView } from 'modules/map/models/nav-view';

@Injectable({
  providedIn: 'root'
})
export class OlMapService {
  private map: OlMap;

  constructor() { }

  public getMap(mapId: string = 'map'): OlMap {
    return mapId && this.map ? this.map : this.createMap(mapId);
  }

  public destroy(): void {
    this.map = undefined;
  }

  private createMap(mapId: string): OlMap {
    return this.map = new OlMap({
      pixelRatio: 1,
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: true, //this.isCanRotate,
        //rotateOptions: this.isCanRotate ? { label: '\u1403' } : undefined
      }),
      target: mapId,
      layers: [
        new TileLayer({
          source: new OSM(
            {
              tilePixelRatio: 1
            })
        })
      ],
      view: new OlView({
        center: fromLonLat([37.61, 55.75]),
        zoom: 11
      })
    });
  }
}
