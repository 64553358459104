import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { MapModule } from 'modules/map/map.module';
import { SharedModule } from 'modules/shared/shared.module';

import { NavPanelComponent } from './components/nav-panel/nav-panel.component';

import { NavigationPage } from './pages/navigation/navigation.page';

@NgModule({
  declarations: [
    NavigationPage,
    NavPanelComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MapModule,
    SharedModule
  ]
})
export class NavigationDesktopModule { }
