import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { HistoryDevice } from 'modules/history/models/history';

@Component({
  selector: 'button-text',
  templateUrl: './button-text.component.html',
  styleUrls: ['./button-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTextComponent {
  @Input() public device: HistoryDevice;

  constructor() { }
}
