import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavClickDirective } from './nav-click.directive';
import { NavResizeDirective } from './nav-resize.directive';
import { NavTapDirective } from './nav-tap.directive';
import { OnlyNumberDirective } from './only-number.directive';

@NgModule({
  declarations: [
    NavClickDirective,
    NavResizeDirective,
    NavTapDirective,
    OnlyNumberDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NavClickDirective,
    NavResizeDirective,
    NavTapDirective,
    OnlyNumberDirective
  ]
})
export class DirectivesModule { }
