import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonGroupComponent } from './button-group/button-group.component';
import { FullScreenButton } from './full-screen/full-screen.button';
import { HistorySelectorButton } from './history-selector/history-selector.button';
import { LogoutButton } from './logout-button/logout.button';
import { PropertyButton } from './property-button/property.button';
import { SidebarButton } from './sidebar-button/sidebar.button';
import { ToggleButton } from './toggle-button/toggle.button';

@NgModule({
  declarations: [
    ButtonGroupComponent,
    FullScreenButton,
    HistorySelectorButton,
    LogoutButton,
    PropertyButton,
    SidebarButton,
    ToggleButton
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ButtonGroupComponent,
    FullScreenButton,
    HistorySelectorButton,
    LogoutButton,
    SidebarButton,
    ToggleButton
  ]
})
export class NavigationButtonsModule { }
