import { ShortDeviceResponse } from "modules/history/models/short-device.response";

export class DeviceResponse extends ShortDeviceResponse {
  Id: number;
  ANo: number;
  CS: string;
  Name: string;
  HW: string;
  CG: CloudResponse[];
  Sbd: string;
  Svc: string;
  FW: string;
  RNo: string;
  VIN: string;
  Brand: string;
  Mdl: string;
  EClr: string;
  Ph: string;
  LTm: string;
  LLat: number;
  LLon: number;
  LCrs: number;
  LSpd: number;
  ITm: string;
}

export interface CloudResponse {
  Cloud: string;
  Group: string;
}
