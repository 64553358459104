export class Navigation {
  public time: Date;
  public latitude: number;
  public longitude: number;
  public course: number;
  public speed: number;

  constructor(init: Partial<Navigation>) {
    Object.assign(this, init);
  }
}

export interface State {
  code: number;
  description: string;
}
