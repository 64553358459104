import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})

export class SearchBoxComponent {
  @Input() public searchByTimer: boolean;
  @Input() public delayTime: number = 2000;
  @Output() public onTextChanged = new EventEmitter<string>();

  private searchText: string;
  private timeout: NodeJS.Timeout;

  public onSearchChange(search: string): void {
    this.searchText = search;

    if (this.searchByTimer) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.onTextChanged?.emit(this.searchText), this.delayTime);
    }
  }

  public search(): void {
    if (!this.searchByTimer) {
      this.onTextChanged?.emit(this.searchText);
    }
  }
}
