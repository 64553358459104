import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'request-button',
  templateUrl: './request.button.html',
  styleUrls: ['./request.button.scss']
})
export class RequestButton {
  @Input() public isRequesting: boolean;

  @Output() public onClicked: EventEmitter<void> = new EventEmitter();
  @Output() public onCancelClicked: EventEmitter<void> = new EventEmitter();

  constructor() { }

  public onClick(): void {
    this.onClicked?.emit();
    this.isRequesting = true;
  }

  public onCancelClick(): void {
    this.onCancelClicked?.emit();
    this.isRequesting = false;
  }
}
