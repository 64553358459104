import { Injectable, Inject } from '@angular/core';

import { Device } from 'modules/devices/models/device';
import { DeviceEvent } from '../models/events';
import { Filter } from 'modules/devices/components-shared/filters/data/filter.class';

import { DeviceCommService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceDataService {
  private _devices: Array<Device> = [];
  private _filter: Filter = new Filter();

  constructor(
    private readonly deviceService: DeviceCommService
  ) { }

  /** Подписка на событие получения устройств */
  public init(): void {
    this.deviceService.on(DeviceEvent.OnDeviceReceived, (device: Device) => {
      if (this._devices.every((localDevice: Device) => localDevice.id !== device.id)) {
        this._devices.push(device);
      }
    });
    this.deviceService.on(DeviceEvent.OnFilterChanged, (filter: Filter) => this._filter = filter);
  }

  public getDevices(useFilter: boolean = false): Array<Device> {
    return useFilter ? this._devices.filter((device: Device) => this._filter.inCriterias(device)) : this._devices;
  }
}
