import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

import { AppEvent, RouteEvent } from 'modules/shared/models/events';
import { PanelBase } from '../data/panel-base';

import { AppCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'route-panel',
  templateUrl: './route.panel.html',
  styleUrls: ['./route.panel.scss']
})
export class RoutePanel extends PanelBase implements OnInit, AfterViewInit {
  @Input() public isShown: boolean = false;
  @Input() public route: RouteEvent;

  constructor(
    private readonly appCommService: AppCommService,
  ) { super(); }

  ngOnInit(): void {
    this.appCommService.on(AppEvent.OnRouteChanged, (route: RouteEvent) => this.isShown = route === this.route);
  }

  ngAfterViewInit(): void {
    super.addControls();
  }
}
