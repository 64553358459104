import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';

import { Device } from 'modules/devices/models/device';
import { DeviceEvent } from 'modules/shared/models/events';
import { DeviceHistory } from 'modules/history/models/history';

import { DeviceCommService } from 'modules/shared/services/communication.service';
import { DeviceDataService } from 'modules/shared/services/device-data.service';
import { trigger, transition, animate, style, state } from '@angular/animations';

@Component({
  selector: 'history-devices',
  templateUrl: './history-devices.component.html',
  styleUrls: ['./history-devices.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expandCollapseState', style({ height: '*' })),
      transition('* => void', [style({ height: '*' }), animate(500, style({ height: "0" }))]),
      transition('void => *', [style({ height: '0' }), animate(500, style({ height: "*" }))])
    ])
  ]
})
export class HistoryDevicesComponent implements OnInit {
  public isExpanded: boolean;
  public devices: Array<DeviceHistory> = [];
  @Input() public get selected() {
    return this._selected;
  }
  public set selected(selected: string) {
    if (this._selected === selected) return;
    this._selected = selected;
    this.onSelectedChanged(selected);
  }

  private _selected: string = '';

  @Output() public onDevicesSelected: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly deviceCommService: DeviceCommService,
    private readonly deviceDataService: DeviceDataService
  ) { }

  ngOnInit(): void {
    this.devices = this.deviceDataService.getDevices(true).map((device: Device) => new DeviceHistory(device.id.toString(), device.getIdentityName()));
    this.deviceCommService.on(DeviceEvent.OnDeviceHistory, (device: Device) => this.selected = device.id.toString());
  }

  public onCheckChanged(): void {
    this._selected = this.devices.filter((item: DeviceHistory) => item.isCheck).map((item: DeviceHistory) => item.id).join(';');
    this.onChanged();
  }

  public onSelectedChanged(text: string): void {
    const ids = text.split(';');
    this.devices.forEach((device: DeviceHistory) => device.isCheck = ids.some((id: string) => device.id === id));
    this.onChanged();
  }

  private onChanged(): void {
    this.onDevicesSelected.emit(this.devices.filter((item: DeviceHistory) => item.isCheck).map((item: DeviceHistory) => item.id).join(';'));
  }
}
