import { Component, Injector, ChangeDetectionStrategy } from '@angular/core';

import { DeviceProperties } from '../data/device-properties';

@Component({
  selector: 'device-props-panel',
  templateUrl: './device-props.panel.html',
  styleUrls: ['./device-props.panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicePropsPanel extends DeviceProperties {

  constructor(injector: Injector) { super(injector); }
}
