import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';

import { GeoRect, MapPoint } from 'modules/history/models/history';
import { MapEvent } from 'modules/shared/models/events';

import { MapCommService } from 'modules/shared/services/communication.service';

@Component({
  selector: 'geo-fence',
  templateUrl: './geo-fence.component.html',
  styleUrls: ['./geo-fence.component.scss']
})
export class GeoFenceComponent implements OnInit, OnDestroy {
  public isDraw: boolean = false;
  public isUsedGeo: boolean = false;
  public get geoRect() {
    return this._geoRect;
  }
  public set geoRect(geoRect: GeoRect) {
    this._geoRect = geoRect;
    this.onGeoFenceChanged.emit(geoRect);
  }

  @Output() public onGeoFenceChanged: EventEmitter<GeoRect> = new EventEmitter();

  private _geoRect: GeoRect = new GeoRect();

  constructor(
    private readonly mapService: MapCommService
  ) { }

  ngOnInit(): void {
    this.mapService.on(MapEvent.OnHistoryAreaDrawn, (coordinates: Array<MapPoint>): void => {
      if (coordinates.length === 5) {
        this.geoRect = new GeoRect(coordinates[3], coordinates[1]);
        this.isDraw = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.mapService.emit({ type: MapEvent.OnDrawCleared });
    this.isDraw && this.onDraw(false);
  }

  public onUseGeoChanged(): void {
    this.isDraw = this.isUsedGeo;
    this.onDraw(this.isDraw);

    this.isUsedGeo === false && this.clearDrawn();
  }

  public onGeoRectChanged(): void {
    if (this.isDraw) {
      this.isDraw = false;
      this.onDraw(this.isDraw);
    }
    this.geoRect.isValid && this.mapService.emit({ type: MapEvent.OnDrawnChanged, value: this.geoRect.getCoordinates() })
  }

  public onDraw(state: boolean): void {
    this.mapService.emit({ type: MapEvent.OnDrawToggled, value: state });
  }

  public clearDrawn(): void {
    this.geoRect = new GeoRect();
    this.mapService.emit({ type: MapEvent.OnDrawCleared });
  }
}
