import { Component, Injector, AfterContentInit, ChangeDetectionStrategy } from "@angular/core";

import Filters from "modules/devices/components-shared/filters/data/filters";

@Component({
  selector: 'filters-menu',
  templateUrl: './filters.menu.html',
  styleUrls: ['./filters.menu.scss']
})
export class FiltersMenu extends Filters implements AfterContentInit {
  public isMenuOpen: boolean = false;

  constructor(injector: Injector) { super(injector); }

  ngAfterContentInit(): void {
    super.getSettings();
    super.staticMenuInit();
  }
}
