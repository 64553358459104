import { Component, ContentChildren, ViewChild, ElementRef, QueryList, ViewEncapsulation } from '@angular/core';

import { Control } from 'ol/control';

import { OlMapService } from 'modules/map/services/ol-map.service';

@Component({
  selector: 'map-nav-panel',
  templateUrl: './nav.panel.html',
  styleUrls: ['./nav.panel.scss'],
  encapsulation: ViewEncapsulation.None //Для вложеных панелей.
})
export class NavPanel {

  @ViewChild('navPanel')
  private readonly navPanel: ElementRef<HTMLElement>;

  @ContentChildren('panel', { read: ElementRef }) panels: QueryList<ElementRef>;

  constructor(
    private readonly mapService: OlMapService
  ) { }

  ngAfterViewInit(): void {
    this.panels.forEach((panel: ElementRef): void => this.navPanel.nativeElement.appendChild(panel.nativeElement));
    const buttonsPanel = new Control({ element: this.navPanel.nativeElement });
    this.mapService.getMap().addControl(buttonsPanel);
  }
}
